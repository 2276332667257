import { apiUrl } from './config';
import { logger } from './logger';

const cacheKey = 'brochureFrontendVersion';

function getNow(): number {
  return new Date().getTime() / 1000;
}

type BrochureVersion = {
  force_reload?: boolean;
  main_css?: string;
  main_homepage_css?: string;
  main_javascript?: string;
  pages_homepage_css?: string;
  seconds_to_cache_again?: number;
  seconds_to_check_reload_again?: number;
  last_cache_update?: number;
  last_reload_check?: number;
};

type FrontendVersion = {
  brochure?: BrochureVersion;
};

function getCachedVersion(): BrochureVersion | null {
  const frontendVersion = sessionStorage.getItem(cacheKey);

  if (!frontendVersion) {
    return null;
  }

  return JSON.parse(frontendVersion) as BrochureVersion;
}

function setCachedVersion(version: BrochureVersion): void {
  sessionStorage.setItem(cacheKey, JSON.stringify(version));
}

function getScriptVersion(scriptMatcher: RegExp): string {
  const allScripts = document.querySelectorAll<HTMLScriptElement>('script[src]');
  const matchedScript = [].find.call(allScripts, (script: HTMLScriptElement) => {
    return scriptMatcher.test(script.src);
  }) as unknown as HTMLScriptElement;

  if (!matchedScript) {
    return '';
  }

  return matchedScript.src.substring(matchedScript.src.lastIndexOf('/') + 1);
}

function getStyleVersion(styleMatcher: RegExp): string {
  const allStyles = document.querySelectorAll<HTMLLinkElement>('link[rel=stylesheet]');
  const matchedStyle = [].find.call(allStyles, (style: HTMLLinkElement) => {
    return styleMatcher.test(style.href);
  }) as unknown as HTMLLinkElement;

  if (!matchedStyle) {
    return '';
  }

  return matchedStyle.href.substring(matchedStyle.href.lastIndexOf('/') + 1);
}

function isCacheUpdateNeeded(version?: BrochureVersion | null): boolean {
  const now = getNow();
  const secondsToCacheAgain = version?.seconds_to_cache_again ?? 60;

  return version?.last_cache_update == null || (now - version.last_cache_update) > secondsToCacheAgain;
}

function isTimeToCheck(version: BrochureVersion): boolean {
  const now = getNow();
  const secondsToCheckReloadAgain = version?.seconds_to_check_reload_again ?? 10;

  return !!version.last_reload_check && (now - version.last_reload_check) > secondsToCheckReloadAgain;
}

async function getVersionInfo(): Promise<BrochureVersion | undefined> {
  return new Promise((resolve) => {
    const request = new XMLHttpRequest();
    request.open('GET', apiUrl + 'content/web/frontendVersion', true);
    request.setRequestHeader('Content-Type', 'application/json');
    request.setRequestHeader('Accept', 'application/json');

    request.onload = ((result: ProgressEvent<XMLHttpRequest>) => {
      const data = JSON.parse(result.target?.response as string) as FrontendVersion;

      resolve(data.brochure);
    }) as typeof request.onload;

    request.send();
  });
}

function reloadIfChanged(remoteVersion: BrochureVersion, localVersion: BrochureVersion): void {

  logger.debug('Local main JS version', JSON.stringify(localVersion.main_javascript));
  logger.debug('Remote main JS version', JSON.stringify(remoteVersion.main_javascript));

  logger.debug('Local main CSS version', JSON.stringify(localVersion.main_css));
  logger.debug('Remote main CSS version', JSON.stringify(remoteVersion.main_css));

  logger.debug('Local homepage CSS version', JSON.stringify(localVersion.pages_homepage_css));
  logger.debug('Remote homepage CSS version', JSON.stringify(remoteVersion.pages_homepage_css));

  console.log('*** LOCAL MAIN JS VERSION ***');
  console.log(localVersion.main_javascript);
  console.log('*** REMOTE MAIN JS VERSION ***');
  console.log(remoteVersion.main_javascript);

  console.log('*** LOCAL MAIN CSS VERSION ***');
  console.log(localVersion.main_css);
  console.log('*** REMOTE MAIN CSS VERSION ***');
  console.log(remoteVersion.main_css);

  console.log('*** LOCAL HOMEPAGE CSS VERSION ***');
  console.log(localVersion.pages_homepage_css);
  console.log('*** REMOTE HOMEPAGE CSS VERSION ***');
  console.log(remoteVersion.pages_homepage_css);

  if (
    remoteVersion && remoteVersion.force_reload
    && remoteVersion.main_javascript
    && remoteVersion.main_css
    && remoteVersion.pages_homepage_css
    && localVersion.main_javascript && remoteVersion.main_javascript != localVersion.main_javascript
    && (!localVersion.main_css || (remoteVersion.main_css != localVersion.main_css))
    && (!localVersion.pages_homepage_css || (remoteVersion.pages_homepage_css != localVersion.pages_homepage_css))
  ) {
    logger.info('Assets version mismatch. Reloading page...');
    window.location.reload();
  }
}

function cacheAndReload(updatedVersion: BrochureVersion, localFiles: BrochureVersion): void {
  setCachedVersion(updatedVersion);
  reloadIfChanged(updatedVersion, localFiles);
}

export async function checkVersionsAndReload() {
  const localVersion = getCachedVersion();
  const localFiles: BrochureVersion = {
    main_javascript: getScriptVersion(/\/main\.[a-z0-9]+\.js$/i),
    main_css: getStyleVersion(/\/main\.[a-z0-9]+\.css$/i),
    pages_homepage_css: getStyleVersion(/\/home\.[a-z0-9]+\.css$/i),
  };
  const now = getNow();

  if (isCacheUpdateNeeded(localVersion)) {
    const currentVersion = await getVersionInfo();

    if (currentVersion) {
      cacheAndReload({
        ...currentVersion,
        last_cache_update: now,
        last_reload_check: now,
      }, localFiles);
    }
  } else if (localVersion && isTimeToCheck(localVersion)) {
    cacheAndReload({
      ...localVersion,
      last_reload_check: now,
    }, localFiles);
  }
}
