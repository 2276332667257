import { Splide } from '@splidejs/splide';

function setupNewTestimonialsCarousel() {
  const slider = new Splide('#testmonial-slider', {
    arrows: false,
    type: 'loop',
    pagination: false,
    autoWidth: true,
    trimSpace: 'move',
    drag: false,
    breakpoints: {
      1024: {
        trimSpace: true,
        type: 'slide',
        padding: { left: 0 },
      },
    },
  });

  const chevronLeft = document.querySelector('.icon-ic-arrow-left.new-jcarousel-control-prev');
  const chevronRight = document.querySelector('.icon-ic-arrow-right.new-jcarousel-control-next');

  chevronLeft.addEventListener('click', () => slider.go('<'));
  chevronRight.addEventListener('click', () => slider.go('>'));

  slider.on('move', () => {
    collapseAllTestimonials();
  });

  slider.mount();

  truncateLongReviews();
}

async function getNewTestimonials() {
  try {
    const response = await fetch(window.app_reviews_api, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    const data = await response.json();

    if (data.length > 0) {
      insertNewTestimonials(data);
    }
  } catch (error) {
    console.log('Error:', error);
  }
}

function insertNewTestimonials(testimonials) {
  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  const allReviews = testimonials
    .sort((a, b) => b.review_submit_date.localeCompare(a.review_submit_date))
    .map(testimonial => {
      const [year, month, day] = testimonial.review_submit_date.split('-');
      const readableDate = `${parseInt(day)} ${months[parseInt(month) - 1]}, ${parseInt(year)}`;
      const stars = '★'.repeat(parseInt(testimonial.star_rating));

      return `
        <li class="splide__slide">
          <div class="new-testimonial" itemscope itemtype="http://schema.org/Review">
            <h3 class="new-testimonial__title line-clamp-1" itemprop="name">${testimonial.review_title}</h3>
            <div class="new-testimonial__info">
              <div class="new-testimonial__info__date">${readableDate}</div>
              <div class="new-testimonial__info__stars">${stars}</div>
            </div>
            <div class="testimonial-text-wrapper">
              <p class="testimonial-text" itemprop="reviewBody">${testimonial.review_text}</p>
            </div>
          </div>
        </li>
      `;
    })
    .join('');

  const itemList = document.querySelector('.new-jcarousel ul');
  if (itemList) {
    itemList.insertAdjacentHTML('beforeend', allReviews);
    setupNewTestimonialsCarousel();
  }
}

function truncateLongReviews() {
  const testimonialTextElements = document.querySelectorAll('.testimonial-text');

  testimonialTextElements.forEach(element => {
    setupClampTextWithReadMore(element, 3);
  });
}

function setupClampTextWithReadMore(element, maxLines) {
  const readMoreText = 'Read more';
  const readLessText = 'Read less';
  let isClamped = true;

  const computedStyle = window.getComputedStyle(element);
  const lineHeight = parseInt(computedStyle.lineHeight);
  const maxHeight = maxLines * lineHeight;

  const checkClamp = () => {
    if (element.scrollHeight > maxHeight) {
      const readMoreButton = document.createElement('button');
      readMoreButton.textContent = readMoreText;
      readMoreButton.className = 'readMoreButton';
      readMoreButton.style.backgroundColor = 'transparent';
      readMoreButton.style.border = 'none';
      readMoreButton.style.color = '#0047ff';
      readMoreButton.style.cursor = 'pointer';
      readMoreButton.style.padding = '0';
      readMoreButton.style.margin = '0';
      readMoreButton.style.font = 'inherit';
      readMoreButton.setAttribute('aria-expanded', 'false');

      element.parentNode.appendChild(readMoreButton);
      element.classList.add(`line-clamp-${maxLines}`);

      readMoreButton.addEventListener('click', toggleClamp);
    }
  };

  const toggleClamp = () => {
    isClamped = !isClamped;
    if (isClamped) {
      element.classList.add(`line-clamp-${maxLines}`);
      element.classList.remove('line-clamp-none');
      element.parentNode.querySelector('.readMoreButton').textContent = readMoreText;
      element.parentNode.querySelector('.readMoreButton').setAttribute('aria-expanded', 'false');
    } else {
      element.classList.add('line-clamp-none');
      element.classList.remove('clamped');
      element.parentNode.querySelector('.readMoreButton').textContent = readLessText;
      element.parentNode.querySelector('.readMoreButton').setAttribute('aria-expanded', 'true');
    }
  };

  const collapse = () => {
    isClamped = true;
    element.classList.add(`line-clamp-${maxLines}`);
    element.classList.remove('line-clamp-none');
    if (element.parentNode.querySelector('.readMoreButton')) {
      element.parentNode.querySelector('.readMoreButton').textContent = readMoreText;
      element.parentNode.querySelector('.readMoreButton').setAttribute('aria-expanded', 'false');
    }
  };

  element.classList.add(`line-clamp-${maxLines}`);
  element.dataset.fullText = element.textContent.trim();

  checkClamp();

  element.collapse = collapse;
}

function collapseAllTestimonials() {
  const testimonialTextElements = document.querySelectorAll('.testimonial-text');
  testimonialTextElements.forEach(element => {
    if (element.collapse) {
      element.collapse();
    }
  });
}

function setupTestimonialsCarousel1() {
  const slider = new Splide('#reviews-slider', {
    arrows: false,
    type: 'loop',
    pagination: false,
    perPage: 3,
    perMove: 1,
    drag: false,
    breakpoints: {
      1199: { perPage: 2 },
      767: { perPage: 1 },
    },
  });

  const chevronLeft = document.querySelector('.jcarousel-control-prev');
  const chevronRight = document.querySelector('.jcarousel-control-next');

  chevronLeft.addEventListener('click', () => slider.go('<'));
  chevronRight.addEventListener('click', () => slider.go('>'));

  slider.mount();
}

export { getNewTestimonials };
