const addQueryToHref = () => {
  if (sessionStorage.gtmParameters) {
    modifyHref(sessionStorage.gtmParameters);
  }
}

function modifyHref(gtmParameters) {
  document.querySelectorAll('.dynamic-url, .dynamic-url-sildenafil, .dynamic-url-cialis, .dynamic-url-cp-delivery, .dynamic-url-cp-renew, .dynamic-url-delivery').forEach(link => {

    try {

      const href = link.getAttribute('href');
      const url = new URL(href);
      const newParams = new URLSearchParams(gtmParameters);

      // console.log("link :", link);
      // console.log("href :", href);

      newParams.forEach((value, key) => {
        url.searchParams.set(key, value);
      });

      return link.setAttribute('href', url.href);

    }

    // WE FOUND AN ISSUE ABOVE ^
    //   const url = new URL(href);
    // FAILS WHEN THE HREF HAS NO DOMAIN, EXAMPLE:
    //   href="/services/depression-anxiety?promo=NEWME"
    // SO WE ADD THIS LOGIC IN "catch {}"
    // TO COVER THESE CASES

    catch {

      function getUrlParameters (parameters) {

        let found = false;
        let searchParsed = {};

        if (parameters) {
          let search = parameters.substring(1);
          let params = search.split("&");

          params.forEach(function (param) {
            let pair = param.split("=");
            searchParsed[pair[0]] = pair[1];
            found = true;
          });
        }

        if (found) return searchParsed;
        return null;
      }

      function serializeQuery (obj) {

        if (!obj) {
          return '';
        }

        let parameters = '';

        for (let key in obj) {
          const value = obj[key];
          if (value === null || value === undefined) {
              continue;
          }
          const delimiter = (!parameters.length ? '?' : '&');
          parameters += delimiter + key + '=' + value;
        }

        return parameters;
      }

      const href = link.getAttribute('href');
      let path = href;
      let query = "";
      let href_array;

      if (href.includes('?')) {
        href_array = href.split('?');
        path = href_array[0];
        query = href_array[1];
      }

      // HERE WE COMBINE ALL PARAMETERS
      // query from URL + gtmParameters from Cache

      query = gtmParameters + "&" + query;

      // HERE WE REMOVE DUPLICATED PARAMETERS

      let queryObj = getUrlParameters(query);
      query = serializeQuery(queryObj);

      return link.setAttribute('href', path + query);

    }
  })
}

if (document.readyState === "complete" || document.readyState === "loaded") {
  addQueryToHref();
} else {
  document.addEventListener('DOMContentLoaded', addQueryToHref);
}
