// Export constants for usage in other modules.
export const environment = ENV;
export const apiUrl = PIMS_API_URL;
export const clinicUrl = CLINIC_URL;
export const xServiceCode = X_SERVICE_CODE;
export const xClientName = X_CLIENT_NAME;
export const trackingApiUrl = TRACKING_API_URL;
export const trackingApiKey = TRACKING_API_KEY;
export const cookiesDomain = COOKIES_DOMAIN;

// Declare legacy constants.
declare global {
  interface Window {
    environment: string;
    apiUrl: string;
    clinicUrl: string;
    xServiceCode: string;
    xClientName: string;
    tracking_url: string;
    tracking_api_key: string;
    cookies_domain: string;
  }
}

window.environment = ENV;
window.apiUrl = PIMS_API_URL;
window.clinicUrl = CLINIC_URL;
window.xServiceCode = X_SERVICE_CODE;
window.xClientName = X_CLIENT_NAME;
window.tracking_url = TRACKING_API_URL;
window.tracking_api_key = TRACKING_API_KEY;
window.cookies_domain = COOKIES_DOMAIN;
