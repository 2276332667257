// jshint devel:true
/* global $:false */
/* global _:false */
import { Splide } from '@splidejs/splide';
import { checkVersionsAndReload } from './version-check';
import shave from 'shave';
import { setupHeroHomepage } from './hero-homepage';
import { equalHeight, capitalizer, debounce, getCookie } from './utils';
import { getNewTestimonials } from './testimonials';

const currentPathname = window.location.pathname.replace('.html', '');

window.addEventListener('load', function () {
  if (window.pages_showing_dynamic_new_design_reviews !== undefined
    && window.pages_showing_dynamic_new_design_reviews.includes(currentPathname)) {
    setupHeroHomepage();
    getNewTestimonials();
  }
});

(function (window) {
  'use strict';

  // https://23andme.atlassian.net/browse/NETOPS-2264
  // this can help to create a new JS file
  // then the browser cache will refreshed after the Clinic reload (once only)
  sessionStorage.fakeValue = "1";

  checkVersionsAndReload();

  //******** Get Business Hours from API *********//

  // From Sprint 112:
  // QA team decided to not cache business hours on Clinic and Brochure

  var business_hours_api = PIMS_API_URL + 'meta/?platform=ios';

  sessionStorage.business_hours_first_part = "";
  sessionStorage.business_hours_second_part = "";
  sessionStorage.business_hours_one_line = "";
  sessionStorage.business_hours_two_lines = "";
  sessionStorage.business_hours_first_line = "";
  sessionStorage.business_hours_second_line = "";

  // window.addEventListener('load', function() {
  document.addEventListener('DOMContentLoaded', function() {

    var filterBusinessHours = function (property) {
      return property.code === 'BUSINESS_HOURS';
    };

    var request = new XMLHttpRequest();
    request.open('GET', business_hours_api, true);
    request.setRequestHeader('Content-Type', 'application/json');
    request.setRequestHeader('Accept', 'application/json');

    request.onload = function (result) {

      var data = JSON.parse(result.target.response);

      if (this.status >= 200 && this.status < 400) {

        var business_hours = data.filter(filterBusinessHours)[0].value;
        var business_hours_array = business_hours.replace(/\n/g, ' ').split(' ');
        var business_hours_array_lines = business_hours.replace(/\n/g, '|||').split('|||');

        if (business_hours_array && business_hours_array.length == 6) {
          sessionStorage.business_hours_first_part = business_hours_array[0] + '<br>' + business_hours_array[1] + ' ' + business_hours_array[2];
          sessionStorage.business_hours_second_part = business_hours_array[3] + '<br>' + business_hours_array[4] + ' ' + business_hours_array[5];
        } else if (business_hours_array && business_hours_array.length >= 7) {
          sessionStorage.business_hours_first_part = business_hours_array[0] + '<br>' + business_hours_array[1] + ' ' + business_hours_array[2];
          sessionStorage.business_hours_second_part = business_hours_array[3] + ' ' + business_hours_array[4] + '<br>' + business_hours_array[5] + ' ' + business_hours_array[6];
        }

        if (business_hours) {
          sessionStorage.business_hours_one_line = business_hours.replace(/\n/g, '&nbsp;&nbsp;');
          sessionStorage.business_hours_two_lines = business_hours.replace(/\n/g, '<br>');
        }

        if (business_hours_array_lines && business_hours_array_lines.length == 1) {
          sessionStorage.business_hours_first_line = business_hours_array_lines[0];
        } else if (business_hours_array_lines && business_hours_array_lines.length >= 2) {
          sessionStorage.business_hours_first_line = business_hours_array_lines[0];
          sessionStorage.business_hours_second_line = business_hours_array_lines[1];
        }

        var firstPartEl = document.querySelectorAll('.dynamic-business-hours-first-part');
        var secondPartEl = document.querySelectorAll('.dynamic-business-hours-second-part');
        var oneLineEl = document.querySelectorAll('.dynamic-business-hours-one-line');
        var twoLineEl = document.querySelectorAll('.dynamic-business-hours-two-lines');
        var firstLineEl = document.querySelectorAll('.dynamic-business-hours-first-line');
        var secondLineEl = document.querySelectorAll('.dynamic-business-hours-second-line');

        if (sessionStorage.business_hours_first_part && firstPartEl && firstPartEl.length) {
          firstPartEl.forEach(function(elem) {
            elem.innerHTML = sessionStorage.business_hours_first_part;
          });
        }

        if (sessionStorage.business_hours_second_part && secondPartEl && secondPartEl.length) {
          secondPartEl.forEach(function(elem) {
            elem.innerHTML = sessionStorage.business_hours_second_part;
          });
        }

        if (sessionStorage.business_hours_one_line && oneLineEl && oneLineEl.length) {
          oneLineEl.forEach(function(elem) {
            elem.innerHTML = sessionStorage.business_hours_one_line;
          });
        }

        if (sessionStorage.business_hours_two_lines && twoLineEl && twoLineEl.length) {
          twoLineEl.forEach(function(elem) {
            elem.innerHTML = sessionStorage.business_hours_two_lines;
          });
        }

        if (sessionStorage.business_hours_first_line && firstLineEl && firstLineEl.length) {
          firstLineEl.forEach(function(elem) {
            elem.innerHTML = sessionStorage.business_hours_first_line;
          });
        }

        if (sessionStorage.business_hours_second_line && secondLineEl && secondLineEl.length) {
          secondLineEl.forEach(function(elem) {
            elem.innerHTML = sessionStorage.business_hours_second_line;
          });
        }

      } else {
        console.log('Error getting business hours: ', result);
      }
    };

    request.send();

  });

  //******** Other Functions *********//
  var host = window.location.host;

  if (host.indexOf('http://') < 0) {
    if (host.indexOf('local') > 0) {
      host = 'http://' + host;
    } else {
      host = 'https://' + host;
    }
  }


  function getTestimonials() {
    var testimonials = [];

    function insertTestimonials() {
      testimonials.sort(function (a, b) {
        return b.review_submit_date.localeCompare(a.review_submit_date);
      });

      var months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      var allReviews = '';

      testimonials.forEach(function (testimonial, index) {
        var parts = testimonial.review_submit_date.split('-');
        var readableDate = months[parseInt(parts[1]) - 1] + ' ' + parseInt(parts[2]) + ', ' + parseInt(parts[0]);

        var stars = '';
        for (var i = 0; i < parseInt(testimonial.star_rating); i++) {
          stars += '★';
        }

        var itemsPerPage = 3;
        var itemClassName;

        switch ((index + 1) % itemsPerPage) {
          case 1: {
            itemClassName = 'first';
            break;
          }
          case 2: {
            itemClassName = 'second';
            break;
          }
          case 0: {
            itemClassName = 'third';
            break;
          }
          default: {
            itemClassName = '';
          }
        }

        var element =
          '<li class="' +
          (itemClassName && itemClassName + ' ') +
          'splide__slide"><div class="testimonial" itemscope itemtype="http://schema.org/Review"><p class="testimonial-item-reviewed" itemprop="itemReviewed">Lemonaid Health online doctor&apos;s office</p><h4 itemprop="name">' +
          testimonial.review_title +
          ' ' +
          stars +
          '</h4><p class="testimonial-text" itemprop="reviewBody">' +
          testimonial.review_text +
          '</p><div class="row"><div class="col-xs-6"><span class="testimonial-date" itemprop="author">' +
          readableDate +
          '</span></div><div class="col-xs-6 text-right"><a href="javascript:void(0)" aria-label="Read More" class="readmore" tabindex="1+">Read more</a></div></div></div></li>';

        allReviews += element;
      });

      // AS SIMON SAID WE DECIDED TO REMOVE CACHING ON REVIEWS
      // We keep the reviews in the cache
      // sessionStorage.testimonials = allReviews;

      document.querySelector('.jcarousel ul').insertAdjacentHTML('beforeend', allReviews);
      setupTestimonialsCarousel1();
    }

    function getAppTestimonials() {
      var request = new XMLHttpRequest();
      request.open('GET', window.app_reviews_api, true);
      request.setRequestHeader('Content-Type', 'application/json');
      request.setRequestHeader('Accept', 'application/json');

      request.onload = function (result) {
        if (this.status >= 200 && this.status < 400) {
          var data = JSON.parse(result.target.response);
          testimonials = data;
          if (testimonials.length > 0) {
            insertTestimonials();
          }
        } else {
          console.log('error', result);
        }
      };
      request.send();
    }

    getAppTestimonials();
  }

  function setupMobileFooter() {
    const linkContainer = document.querySelector('.footer__mobile-services');

    if (linkContainer) {
      linkContainer.onclick = function (event) {
        if (event.target.closest('#delivery-services')) {
          hideAllShowOne('#delivery-services');
        } else if (event.target.closest('#treatment-services')) {
          hideAllShowOne('#treatment-services');
        } else if (event.target.closest('#lab-test')) {
          hideAllShowOne('#lab-test');
        } else if (event.target.closest('#medicines')) {
          hideAllShowOne('#medicines');
        } else if (event.target.closest('#consent-privacy-legal')) {
          hideAllShowOne('#consent-privacy-legal');
        } else if (event.target.closest('#about-us')) {
          hideAllShowOne('#about-us');
        }
      };
    }

    function hideAllShowOne(section) {
      const element = document.querySelector(section);

      function closeBlock() {
        const footerBlocks = document.querySelectorAll('.footer__mobile-services__block');
        if (footerBlocks) {
          footerBlocks.forEach(function (block) {
            block.classList.remove('open');
          });
        }
      }

      if (element && element.classList) {
        if (element.classList.contains('footer__mobile-services__block') && element.classList.contains('open')) {
          closeBlock();
        } else {
          closeBlock();
          element.classList.add('open');
        }
      }
    }
  }

  function showFaqSection(section, subsection) {
    const href = window.location.hash;

    function hideAllShowOne() {
      const faqCategories = document.querySelectorAll('.faq-category');
      faqCategories.forEach(function (category) {
        category.style.display = 'none';
      });
      const visibleCategory = document.querySelector('#' + section);
      if (visibleCategory) {
        visibleCategory.style.display = 'block';
      }
      const menuItems = document.querySelectorAll('a[data-faq]');
      menuItems.forEach(function (menuItem) {
        menuItem.classList.remove('selected');
      });
      const selectedMenuItem = document.querySelector('a[data-faq=' + section + ']');
      if (selectedMenuItem) {
        selectedMenuItem.classList.add('selected');
      }
    }
    const faqItems = document.querySelector('.faq-items');
    if (faqItems) {
      faqItems.onclick = function (event) {
        event.stopPropagation();
        if (event.target.closest('.accordion__title')) {
          const details = event.target.closest('details');
          details.open = !details.open;
          details.scrollIntoView({ behavior: 'smooth' });
        }
      };
    }

    if (!href) {
      hideAllShowOne();
      return;
    }

    hideAllShowOne();

    if (document.body.scrollTop !== 0) {
      window.scrollTo(0, 0);
    }

    if (subsection) {
      const answers = document.querySelectorAll('#' + section + ' .faq-answer-el');

      answers.forEach(function (answer) {
        if (answer.getAttribute('id') === subsection) {
          answer.closest('details').open = true;
        }
      });
    }
  }

  function setupToc() {
    const toc = document.querySelector('.faq-menu');
    const faqCategories = document.querySelectorAll('.faq-category');

    faqCategories.forEach(function (category) {
      const categoryId = category.getAttribute('id');
      const link = document.createElement('a');
      link.textContent = capitalizer(categoryId.split('_').join(' '));
      link.setAttribute('href', 'javascript:void(0)');
      link.setAttribute('class', 'faq-item');
      link.setAttribute('data-faq', categoryId);
      link.setAttribute('tabindex', '1+');
      toc.append(link);

      const answerElements = category.querySelectorAll('.faq-answer-el');

      answerElements.forEach(function (answer) {
        const answerId = answer.getAttribute('id');
        faqLinkList[answerId] = categoryId;
      });

      faqLinkList[categoryId] = categoryId;
    });

    let cleanHash = hash.replace('#', '');
    if (cleanHash === '') {
      cleanHash = 'save-time-and-money';
    }
    showFaqSection(faqLinkList[cleanHash], cleanHash);
  }

  function setupRipples() {
    const rippleButtons = document.querySelectorAll('.ripple');

    rippleButtons.forEach(function (button) {
      button.addEventListener('click', function (event) {
        const div = document.createElement('div');
        const btnOffset = event.target.getBoundingClientRect();
        const xPos = event.pageX - btnOffset.left;
        const yPos = event.pageY - btnOffset.top;

        div.className = 'ripple-effect';
        div.style.left = xPos + 'px';
        div.style.right = yPos + 'px';
        this.append(div);

        setTimeout(function () {
          div.remove();
        }, 2000);
      });
    });
  }

  function setActiveNav() {
    let page = window.page.split('/');
    if (page && page.length > 1) {
      page = page[1].replace(/\/$/, '');
      page = decodeURIComponent(page);

      if (page === 'services') {
        const servicesBtn = document.querySelector('#services-dropdown-btn');
        servicesBtn.classList.add('header-menu__link--active');
      } else if (page === 'our-story' || page === 'meet-our-lemons' || page === 'careers') {
        const aboutUsBtn = document.querySelector('#about-us-dropdown-btn');
        aboutUsBtn.classList.add('header-menu__link--active');
      } else {
        const headerLinks = document.querySelectorAll('.header-menu__link');
        headerLinks.forEach(function (link) {
          var href = link.getAttribute('href');
          if (href) {
            href = href.replace(/\//, '');
            var currentLocation = page; //The current location will either be a page or a hash/anchor
            if (page.length < 1) {
              currentLocation = window.hash;
            }
            if (currentLocation === href) {
              link.classList.add('header-menu__link--active');
            }
          }
        });
      }
    }
  }

  function truncateDoctorText() {
    /* Make sure all medical team blocks have restricted height / content initially */
    shave('.team-collapsed-text', 100);

    const links = document.querySelectorAll('.team-member-toggle a');
    links.forEach(function (link) {
      link.classList.remove('readless');
      link.classList.add('readmore');
      link.textContent = 'Read more';
    });

    const container = document.querySelector('.team-member-list');

    if (container) {
      /* When you click to read more, expand text, hide read more, and show read less..or change icon and toggle */
      container.addEventListener('click', function (event) {
        const target = event.target;
        if (target.tagName === 'A' && target.closest('.team-member-toggle')) {
          const textField = target.closest('.team-member').querySelector('.team-collapsed-text');
          if (target.classList.contains('readmore')) {
            target.classList.remove('readmore');
            target.classList.add('readless');
            target.textContent = 'Less';
            textField.textContent = textField.textContent.replace('…', '');
          } else if (target.classList.contains('readless')) {
            target.classList.remove('readless');
            target.classList.add('readmore');
            target.textContent = 'Read more';
            shave(textField, 100);
          }
        }
      });
    }
  }

  function isAuthorized() {
    var cookieObj = getCookie();
    return !!cookieObj.authorized;
  }

  function getUserName() {
    var cookieObj = getCookie();
    return cookieObj.username;
  }

  function showAssessmentLink() {
    var cookieObj = getCookie();
    return cookieObj.showAssessmentLink;
  }

  function handleAuthMenu() {
    if (isAuthorized()) {
      const signInBtnContainer = document.querySelector('.header-menu__sign-in-btn-container');
      const messagesLinkContainer = document.querySelector('.header-menu__messages-link-container');
      const accountLinkContainer = document.querySelector('.header-menu__account-link-container');
      const sidebarAccountLink = document.querySelector('.sidebar-menu__account-link');
      const signOutLink = document.querySelector('.sign-out-link');
      const aboutUsDropdownMenu = document.querySelector('.about-us-dropdown-menu');
      const breakLine = document.querySelector('.header-menu__break-line');
      const accountList = document.querySelector('.sidebar-menu__account-item');
      signInBtnContainer.classList.add('signed-in');
      messagesLinkContainer.classList.add('signed-in');
      accountLinkContainer.classList.add('signed-in');
      sidebarAccountLink.classList.add('signed-in');
      signOutLink.classList.add('signed-in');
      aboutUsDropdownMenu.classList.add('signed-in');
      accountList.classList.add('signed-in');
      breakLine.classList.add('signed-in');

      const userName = getUserName();
      if (userName) {
        const mobileTitle = document.querySelector('.sidebar-menu__title');
        mobileTitle.textContent = 'Welcome, ' + userName;
        mobileTitle.classList.add('sidebar-menu__title--visible');
      }

      const shouldShowAssessmentLink = showAssessmentLink();
      if (!shouldShowAssessmentLink) {
        const item = document.querySelector('.mobile-services-list__assessment-item');
        item.classList.add('hidden-service');
      }
    }
  }

  window.main_js_setup = function main_js_setup() {
    // console.log("EXECUTING: main_js_setup");

    setupMobileFooter();

    handleAuthMenu();

    const openFaqMenuEl = document.querySelector('.open-faq-menu');
    const closeFaqMenuEl = document.querySelector('.close-faq-menu');
    const faqMenu = document.querySelector('.faq-menu');

    if (openFaqMenuEl) {
      openFaqMenuEl.onclick = function () {
        faqMenu.classList.toggle('open');
      };
    }

    if (closeFaqMenuEl) {
      closeFaqMenuEl.onclick = function () {
        faqMenu.classList.toggle('open');
      };
    }

    if (faqMenu) {
      const onLinkClicked = function (event) {
        if (event.target.tagName === 'A') {
          if (faqMenu.classList.contains('open')) {
            faqMenu.classList.toggle('open');
          }
          var faqSection = event.target.getAttribute('data-faq');

          window.location.hash = faqSection;
          showFaqSection(faqSection);
        }
      };

      faqMenu.addEventListener('click', onLinkClicked);
      faqMenu.addEventListener('keypress', onLinkClicked);
    }

    /* Set the active nav menu item */
    setActiveNav();

    /* Trancate Doctor intros and add event listeners to expand and collapse */
    truncateDoctorText();

    /* Set up the testimonail scroller */
    setupToc();

    // We get reviews data just on pages showing the carrousel of reviews
    // This avoids errors
    let currentPathname = window.location.pathname;
    currentPathname = currentPathname.replace('.html', '');

    if (window.pages_showing_dynamic_reviews.includes(currentPathname)) {
      getTestimonials();
    }

    setupRipples();
    if (window.pages_showing_reviews_carousel !== undefined) {
      if (window.pages_showing_reviews_carousel.includes(currentPathname)) {
        initReviewsCarousel();
      }
    }

    if (window.pages_showing_photo_carousel !== undefined) {
      if (window.pages_showing_photo_carousel.includes(currentPathname)) {
        initPhotoCarousel(currentPathname);
      }
    }

    if (window.pages_showing_pets_carousel !== undefined) {
      if (window.pages_showing_pets_carousel.includes(currentPathname)) {
        initPetsCarousel();
      }
    }
    // Carousel not found anywhere in code
    // To be uncommented if/when it is reintroduced
    //
    // if (window.pages_showing_sponsors_carousel !== undefined) {
    //   if (window.pages_showing_sponsors_carousel.includes(currentPathname)) {
    //     initSponsorsCarousel();
    //   }
    // }

    // Carousel replaced for now
    // To be uncommented if/when it is reintroduced
    //
    // if (window.pages_showing_cards_carousel !== undefined) {
    //   if (window.pages_showing_cards_carousel.includes(currentPathname)) {
    //     initHowWeCanHelpCarousel();
    //   }
    // }
  }

  const sidebarServiceMenuLink = document.querySelector('.sidebar-menu__services-menu-link');

  if (sidebarServiceMenuLink) {
    sidebarServiceMenuLink.addEventListener('click', function () {
      var serviceList = document.querySelector('.mobile-services-list');
      var arrowIconServices = document.querySelector(
        '.sidebar-menu__services-menu-link .icon-rebrand-caret-down.services'
      );

      if (serviceList.classList.contains('hidden-service')) {
        serviceList.classList.remove('hidden-service');
        arrowIconServices.classList.add('icon-rebrand-caret-up');
      } else {
        serviceList.classList.add('hidden-service');
        arrowIconServices.classList.remove('icon-rebrand-caret-up');
      }
    });
  }

  const sidebarAboutUsLink = document.querySelector('.sidebar-menu__about-us-link');

  if (sidebarAboutUsLink) {
    sidebarAboutUsLink.addEventListener('click', function () {
      var aboutUsList = document.querySelector('.mobile-about-us-list');
      var arrowIcon = document.querySelector('.sidebar-menu__about-us-link .icon-rebrand-caret-down');

      if (aboutUsList.classList.contains('hidden-service')) {
        aboutUsList.classList.remove('hidden-service');
        arrowIcon.classList.add('icon-rebrand-caret-up');
      } else {
        aboutUsList.classList.add('hidden-service');
        arrowIcon.classList.remove('icon-rebrand-caret-up');
      }
    });
  }

  const sidebarAccountLink = document.querySelector('.sidebar-menu__account-link');

  if (sidebarAccountLink) {
    sidebarAccountLink.addEventListener('click', function () {
      var accountList = document.querySelector('.mobile-account-list');
      var arrowIcon = document.querySelector('.sidebar-menu__account-link .icon-rebrand-caret-down');

      if (accountList.classList.contains('hidden-service')) {
        accountList.classList.remove('hidden-service');
        arrowIcon.classList.add('icon-rebrand-caret-up');
      } else {
        accountList.classList.add('hidden-service');
        arrowIcon.classList.remove('icon-rebrand-caret-up');
      }
    });
  }

  function initReviewsCarousel() {
    let slider = new Splide('#reviews-carousel', {
      arrows: false,
      type: 'loop',
      pagination: false,
      autoWidth: true,
      drag: false,
      breakpoints: {
        768: {
          destroy: true,
        },
      },
    });

    const chevronLeft = document.querySelector(
      '.icon-ic-arrow-left.reviews-carousel__control.reviews-carousel__control--prev'
    );
    chevronLeft.onclick = function () {
      slider.go('<');
    };

    const chevronRight = document.querySelector(
      '.icon-ic-arrow-right.reviews-carousel__control.reviews-carousel__control--next'
    );
    chevronRight.onclick = function () {
      slider.go('>');
    };

    slider.mount();
  }

  function initPhotoCarousel(currentPathName) {
    let slider;

    if (currentPathName === '/our-story') {
      slider = new Splide('#our-life-carousel', {
        arrows: false,
        type: 'loop',
        pagination: true,
        autoWidth: true,
        drag: true,
        perPage: 4,
        slideFocus: false,
        keyboard: 'focused',
        breakpoints: {
          1800: {
            perPage: 3,
          },
          1100: {
            perPage: 2,
          },
          1023: {
            perPage: 1,
          },
        },
      });
    } else if (currentPathName === '/careers') {
      slider = new Splide('#our-life-carousel', {
        arrows: false,
        type: 'loop',
        pagination: true,
        autoWidth: true,
        drag: true,
        perPage: 3,
        slideFocus: false,
        breakpoints: {
          1400: {
            perPage: 4,
          },
          1060: {
            perPage: 3,
          },
          1000: {
            perPage: 1,
          },
        },
      });
    }

    slider.on('pagination:mounted', function (data) {
      data.list.classList.add('our-life__pagination');

      data.items.forEach(function (item) {
        item.button.ariaLabel =
          'Pictures of lemonaid health team slide ' + (item.page + 1) + ' of ' + data.items.length;
        item.button.tabIndex = 1;
      });
    });

    slider.mount();
  }

  function initPetsCarousel() {
    let desktopSlider = new Splide('#pets-carousel', {
      arrows: false,
      type: 'loop',
      pagination: true,
      autoWidth: true,
      drag: true,
      perPage: 3,
      slideFocus: false,
    });
    let slider = new Splide('#pets-carousel-mobile', {
      arrows: false,
      type: 'loop',
      pagination: true,
      autoWidth: true,
      drag: true,
      focus: 'center',
      perPage: 1,
      slideFocus: false,
    });

    const chevronLeft = document.querySelector('.icon-ic-arrow-left.jcarousel-control-prev');
    if (chevronLeft) {
      chevronLeft.onclick = function () {
        desktopSlider.go('-${1}');
      };
    }

    const chevronRight = document.querySelector('.icon-ic-arrow-right.jcarousel-control-next');
    if (chevronRight) {
      chevronRight.onclick = function () {
        desktopSlider.go('+${1}');
      };
    }

    desktopSlider.on('pagination:mounted', function (data) {
      data.list.classList.add('our-life__pagination');

      data.items.forEach(function (item) {
        item.button.ariaLabel = 'Pictures of our pets slide ' + (item.page + 1) + ' of ' + data.items.length;
        item.button.tabIndex = 1;
      });
    });

    slider.on('pagination:mounted', function (data) {
      data.list.classList.add('our-life__pagination');
    });

    desktopSlider.mount();
    slider.mount();
  }

  function initSponsorsCarousel() {
    let slider = new Splide('#our-investors__carousel', {
      arrows: false,
      type: 'loop',
      autoWidth: true,
      drag: false,
      autoplay: true,
      pagination: false,
      interval: 3000,
      slideFocus: false,
    });

    slider.on('moved', () => {
      document.querySelectorAll('#our-investors__carousel-list .splide__slide').forEach((el) => {
        if (!el.classList.contains('splide__slide--clone')) {
          el.firstElementChild.setAttribute('tabindex', '1+');
        }
      });
    });
    slider.on('mounted', () => {
      document.querySelectorAll('#our-investors__carousel-list .splide__slide').forEach((el) => {
        if (!el.classList.contains('splide__slide--clone')) {
          el.firstElementChild.setAttribute('tabindex', '1+');
        }
      });
    });

    slider.mount();
  }

  function initHowWeCanHelpCarousel() {
    const slider = new Splide('#how-can-we-help-carousel', {
      arrows: true,
      type: 'slide',
      loop: false,
      pagination: false,
      autoWidth: true,
      drag: true,
      gap: 16,
      slideFocus: false,
      keyboard: 'focused',
      breakpoints: {
        1440: {
          perMove: 3,
        },
        1439: {
          perPage: 2,
        },
        1023: {
          perPage: 1,
        },
      },
    });

    const chevronLeft = document.querySelector('.card-carousel__arrow--prev');
    const chevronRight = document.querySelector('.card-carousel__arrow--next');

    slider.on('visible', function (slide) {
      if (slide.index === 0) {
        chevronLeft.classList.add('inactive');
      }

      if (slide.index === slider.length - 1) {
        chevronRight.classList.add('inactive');
      } else {
        chevronRight.classList.remove('inactive');
      }
    });

    slider.on('hidden', function (slide) {
      if (slide.index === 0) {
        chevronLeft.classList.remove('inactive');
      }
      if (slide.index === slider.length - 1) {
        chevronRight.classList.remove('inactive');
      }
    });

    slider.mount();
  }

  function initHowWeCanHelpCarousel() {

      const slider = new Splide( '#how-can-we-help-carousel', {
        arrows: true,
        type: "slide",
        loop: false,
        pagination: false,
        autoWidth: true,
        drag: true,
        gap: 16,
        slideFocus: false,
        keyboard: 'focused',
        breakpoints: {
          1440: {
            perMove: 3
          },
          1439: {
            perPage: 2
          },
          1023: {
            perPage: 1
          },
        },
        padding: { left: 24, right: 24 },
      });

      const chevronLeft = document.querySelector('.card-carousel__arrow--prev');
      const chevronRight = document.querySelector('.card-carousel__arrow--next');

      slider.on('visible', function ( slide ) {
        if (slide.index === 0) {
          chevronLeft.classList.add('inactive');
        }

        if (slide.index === slider.length - 1) {
          chevronRight.classList.add('inactive');
        } else {
          chevronRight.classList.remove('inactive');
        }
      });

      slider.on('hidden', function (slide) {
        if (slide.index === 0) {
          chevronLeft.classList.remove('inactive');
        }
        if (slide.index === slider.length - 1) {
          chevronRight.classList.remove('inactive');
        }
      })

    slider.mount();

  }
}(window));
