const base64Placeholders = {
  afterPill: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAbCAIAAACSpRrNAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAD/AP8A/6C9p5MAAAAHdElNRQfoBg0SExLGHVn2AAADMUlEQVRIx21WUW5jOQwjaadz/xPtfuyldmKR8yHbLy3GCIogySMlipTL//79x2vVetf7Xe/f6/1/vd+1fnutuJIQoDTmHK/XfP2aX1/z69f8+jVfX+P1GvPFMUSBBIH0sePYtmdy3rrKVVWrVq3ltVyFGKSkgKCooi07H4dJGIL425k5DGVXn7XWWrWWayEhqaGQkOhSdTWHAzlQQS4H94uYsR03RVWtfq1qgiQkR0ZAaqhquBy3CMrW5FvxxCYlGM6jmF2uh2NVLVclJhkEJKtULns43QCQNFYCXuCGRgCwCRJ3H/ajU1XPgGQAashHHDvZEu0WADTF0wcJIpl3Vv44dbFikiDLHrbzjBf3DYIEDPacmygAuwOfV897/3HiGF3c89W3g55x8JcRkC3VPI8D+4FWNRt6+wONdFFP2feZsJ/rSfD2o7l7xJ4W2s8kyA9DfJyPUvBBlzy43J0A7BzkVLnbIkmKDBHxfLDNffHyTVQqnQRm69SjmKfdXTRJqfEFJTbvR09Wf1A4ZAJYJMJjp3QHOJJec/VuEAMSwkcH/IFt26ZM04B0Mpzs36dddAZAgjr6SEoiH3TyFraVsWO5UjQgxMj9MXo5kfMxxZnx0Z8QHREPeD7W5V6PElkdZmYT3ANydiyRELljuCQC7rgu/s28LdeeexLF3ArgcnTQTgfHyltwErn5PIb/3O5U7dYO/s+DeUPfQh2j8ATiivchz+VwsdDFS8x3dVruuROAix7edX4a4Ee+TgfF2uWfmWwvnz3Hbzn4eVPw48ro6B8DwQfP5QITSJFIest6wwwS86kXd8I4zqGYXCKALaUd2fYxrelttM9CW9x5NWtrtpkitq0iJTl0t+/ACSuAkXYfnqCHd/cEU9s0ghwzVCRYYKi78Xdr6kaDxDGDQuityrODkUOdTA3tLyxIkBBRImLC9r0Gqf78wDXHE8F2ga8XevxzaDBgwjGYYIRJjeyl1EuyL8TzEber0P9U5GQEMM5dtTmSOcbo/dSSM8MjBEyGtrzvtJsLnm151MjZTo2O3EvbsOcYU4ABBx5w6wqEMJnoXLs75rnJxZO8B9QbN67m+AMdVz4HrIXmaQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNC0wNi0xM1QxNzo0Mzo0OSswMDowMMnP5dYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDUtMDRUMDE6NDk6MjIrMDA6MDDq4iWqAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDI0LTA2LTEzVDE4OjE5OjE4KzAwOjAwQBWQ2wAAAABJRU5ErkJggg==',
  steadyMD: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAcACADASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAABQYEBwECA//EAB8QAAICAgIDAQAAAAAAAAAAAAECAAQDEQUhBhITIv/EABcBAAMBAAAAAAAAAAAAAAAAAAABBAL/xAAYEQADAQEAAAAAAAAAAAAAAAAAAQIRA//aAAwDAQACEQMRAD8AL3bygEbgHPlGTJ1BVm+7k6MzTd3buSU8K+c6Ofj2QIyyxOLtAoO5WXEew0Y4cdYKqO44oXWEin6Fb7AGHaVD1I6kHgBtBuMuEAEQa0JrCZQr+qiElf5icqg/M0tkgHUcrDFVp//Z',
  a1c: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAcACADASIAAhEBAxEB/8QAGQABAQADAQAAAAAAAAAAAAAAAwUCBAYH/8QAHhAAAQQCAwEAAAAAAAAAAAAAAQACAwQhMQUREkH/xAAXAQADAQAAAAAAAAAAAAAAAAACAwQF/8QAGhEAAwEBAQEAAAAAAAAAAAAAAAECAxESIf/aAAwDAQACEQMRAD8A8yZXd1pI2Ag5XTQceCzSCemGnAWVSHqCVFXLviU8cXDSr06w7yFWjqt8aSlATj4aVe0A3pKWiXIUsDp2FZ45ocMotr8lGUdDjjLDpVKo9BYvjaPiStg4U8aNsdeS4f/Z',
  ed: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAwICQoJBwwKCQoNDAwOER0TERAQESMZGxUdKiUsKyklKCguNEI4LjE/MigoOk46P0RHSktKLTdRV1FIVkJJSkf/2wBDAQwNDREPESITEyJHMCgwR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0f/wAARCAAcACADAREAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAIEBgEDBf/EACAQAAIBBAIDAQAAAAAAAAAAAAECAAMEMVEFERITIWH/xAAYAQEBAQEBAAAAAAAAAAAAAAAAAwECBP/EABcRAQEBAQAAAAAAAAAAAAAAAAARARL/2gAMAwEAAhEDEQA/ALyzAT01GBGBikbfH5FYUrNog3NXo5karmC2q9nMZpuOgjjxm1xAXXc2kVitehjmQq/LNveBTmKRMHIgDM3pnJG5H9jo5Vf2PuSWh0qvuA/ufcEKar7gj//Z',
  genetalHerpes: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAwICQoJBwwKCQoNDAwOER0TERAQESMZGxUdKiUsKyklKCguNEI4LjE/MigoOk46P0RHSktKLTdRV1FIVkJJSkf/2wBDAQwNDREPESITEyJHMCgwR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0f/wAARCAAcACADAREAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAgMABAUB/8QAHhAAAwADAAIDAAAAAAAAAAAAAAECAwQREjEhQWH/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAQID/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwC1V8ZxjqKdjx+zcZMja6/ZUWceXyQGNkp9MRrSaujSO47roGhr5XwoG9fpELet+ARazT9FD4wtIKdPyZBpIoJSgCUoD//Z',
  pe: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDABALDA4MChAODQ4SERATGCkbGBYWGDIkJh4pOzQ+PTo0OThBSV5QQUVZRjg5Um9TWWFkaWppP09ze3Jmel5naWX/2wBDARESEhgVGDAbGzBlQzlDZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWX/wAARCAAcACADAREAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAwQAAQIF/8QAGxAAAgMBAQEAAAAAAAAAAAAAAAECAyERIhL/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAQIE/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwDpxtOfXVjM7OoAaloFTh9IyoabTBrTeGozUg9KhqtJogXnHki2EqmsIBp8ZUMVWEUO1+jdZidwy0BJ+ggkHgpH/9k=',
  stopSmoking: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDABALDA4MChAODQ4SERATGCkbGBYWGDIkJh4pOzQ+PTo0OThBSV5QQUVZRjg5Um9TWWFkaWppP09ze3Jmel5naWX/2wBDARESEhgVGDAbGzBlQzlDZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWX/wAARCAAcACADAREAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAgMEAAX/xAAbEAACAwEBAQAAAAAAAAAAAAAAAQIDESEEMf/EABgBAQEBAQEAAAAAAAAAAAAAAAEAAgME/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwBThiPLI0lsT01hwKiyAZcMl0tTNSKFyrTNYWVPCwJb4YZxDruGKGq02TYS1FjGkelagxamQQii+m1VdPwXOtcuBVH/2Q==',
  uti: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAA8KCw0LCQ8NDA0REA8RFiUYFhQUFi0gIhslNS84NzQvNDM7QlVIOz9QPzM0SmRLUFdaX2BfOUdob2dcblVdX1v/2wBDARARERYTFisYGCtbPTQ9W1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1v/wAARCAAcACADAREAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAwUEAAL/xAAdEAACAgIDAQAAAAAAAAAAAAAAAQMhAhEEEjFB/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAEDAv/EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIRAxEAPwCxPMknZhtOm5N+iMKnbYGRSvQw88rle2Ik/Kbtl6EB4bNyM2n1pBYJUmadv6TaDhI+xqFVGCSisTp++wpxFdkFHYqxwVqhbKxOtkFuwoj/2Q==',
  acidReflux: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAcACADASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAABgMCBQf/xAAdEAACAgMBAQEAAAAAAAAAAAABAgAFAwQREiEy/8QAFwEAAwEAAAAAAAAAAAAAAAAAAgMEAf/EABgRAQADAQAAAAAAAAAAAAAAAAEAAgMR/9oADAMBAAIRAxEAPwBlYZPKkQVcn2zRPZ5w3eGFrBh97I7dGXUqJD+Zzi6ZDFdnXf8AUza5QqtBNltsrnhjKdYGgE9m2bDoPTODY768P2TsMjKp4YStNnL0j1KL5HYjPV5KWe779AGGNtTkYy75XZj0zeJQT9mFQILZtP/Z',
  acne: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAA8KCw0LCQ8NDA0REA8RFiUYFhQUFi0gIhslNS84NzQvNDM7QlVIOz9QPzM0SmRLUFdaX2BfOUdob2dcblVdX1v/2wBDARARERYTFisYGCtbPTQ9W1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1v/wAARCAAcACADAREAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAABQYCBAf/xAAfEAACAgICAwEAAAAAAAAAAAAAAQIDBBEFIRIxMiL/xAAYAQADAQEAAAAAAAAAAAAAAAAAAQIDBP/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8AN3doh0BObT5JkjC7nY2m3oJUWKMJOqRpKitIlpkN1W+CaZNAHyFa0whUu5a1J6LjKtCjPYm6Nr/LFQB8k+mJNLOW9yZcY0/1eiY6ErflhSAuS9MSaW8v6ZcZV//Z',
  anxiety: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAcACADASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAABAUGAgMI/8QAHRAAAgIDAQEBAAAAAAAAAAAAAQIAAwQFESESFP/EABcBAAMBAAAAAAAAAAAAAAAAAAECAwT/xAAZEQACAwEAAAAAAAAAAAAAAAAAAgEDESH/2gAMAwEAAhEDEQA/AOZ8demPdfjFueRZrqfpx5LbT4PUB5IO2GmpNAPzFF7yYqfkx7nUCusydvfjmIk6UdcBddj/AA46JZ611rqkzWAG8jBbXWvwwWDUzw37vNUKQDJK3I+nPsI21zknpiZWJaPXHCdrbJ//2Q==',
  asthma: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAcACADASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAABgcCBAUD/8QAIBAAAQQCAwADAAAAAAAAAAAAAQACAwQFERITMRQhIv/EABcBAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAbEQADAAIDAAAAAAAAAAAAAAAAAQIRIQMTMf/aAAwDAQACEQMRAD8Aelmv2xkaQZmcJye53FMGDiW/aqZKKMsPiTyLMjYeGLFlb4rvPFsY/Iuj0NqWShBkICrwUnO8CzOqk9FypNbCSTMsiOuSq2c22RpAcl/l7kzZDpy40bUrz+nLRdEswGBn7ZVs0Gt4jaGMeSSNokpnTUEhV4f/2Q==',
  birthControl: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAcACADASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAABQYCBAcD/8QAIhAAAQQBBAIDAAAAAAAAAAAAAQACAwUEERIhMQYiFEFC/8QAFwEAAwEAAAAAAAAAAAAAAAAAAgMEBf/EABgRAQEBAQEAAAAAAAAAAAAAAAECAAMR/9oADAMBAAIRAxEAPwDPKWk1e31WkePVIYG+qoUuFtI1anmqha1o4UM37tVgnFKvF2NHCLiIgLlhloARBjmkKiZHS1SZExacx/lE44DEOkxGFgHSqZMbQDwkvPzOOrWFfKMf2ouuBH25UrQloOiTbPKlaTo5DNI42BN//9k=',
  cholesterol: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAA8KCw0LCQ8NDA0REA8RFiUYFhQUFi0gIhslNS84NzQvNDM7QlVIOz9QPzM0SmRLUFdaX2BfOUdob2dcblVdX1v/2wBDARARERYTFisYGCtbPTQ9W1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1v/wAARCAAcACADAREAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQQDAP/EABwQAAICAwEBAAAAAAAAAAAAAAABAgQDETEhQf/EABgBAAMBAQAAAAAAAAAAAAAAAAABAgME/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwDC43LZnrowZLFKUuAFVaq38JpwtWp714ETa6xV94M5WOOonLgAhXppfAInWrJfBxNqa3BJsKIij5InVrq8kGilK7WiozoezJtsVOJV0zqlWDoQyOGTSKS//9k=',
  coldSore: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAoHBwgHBgoICAgLCgoLDhgQDg0NDh0VFhEYIx8lJCIfIiEmKzcvJik0KSEiMEExNDk7Pj4+JS5ESUM8SDc9Pjv/2wBDAQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wAARCAAcACADAREAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAABgQCBQf/xAAdEAABBAMBAQAAAAAAAAAAAAABAAIDBAUSIRMR/8QAFwEBAQEBAAAAAAAAAAAAAAAABAMCAf/EABgRAQEBAQEAAAAAAAAAAAAAAAABAgMh/9oADAMBAAIRAxEAPwDmdZpe4KVXyRY+lsBxH1onGVdrHDzPFmabuRq/W83HiRmi6jLHRgvC5qu4hjjIRqOIuqZiLrMQ8jxYlbsD8vEAXJOKJ0iHHvDXhU0nimOMmboOo2obirLU7REeqcjdohlZQ5xScQPpWprktePivpHJJjpn6jqNovFV2pX+Z6pxvV8GbryXn6UnAe3/2Q==',
  darkSpots: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAcACADASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABgUHAv/EAB4QAAEEAwEBAQAAAAAAAAAAAAEAAgMFERIhBBMy/8QAFQEBAQAAAAAAAAAAAAAAAAAABQT/xAAZEQADAQEBAAAAAAAAAAAAAAAAASEDMRL/2gAMAwEAAhEDEQA/AObmf6Nd1Z9eR52S2efZpyUYuXDDkSnR3zDPrSPDnKFOOlI7Ugucj846Vfk4Hbqm8e4GNpRG4n/XUwt+NcgN047OUKVEm4HLGTJKjS9JVD1kklTpFblwO26f/9k=',
  depression: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAoHBwkHBgoJCAkLCwoMDxkQDw4ODx4WFxIZJCAmJSMgIyIoLTkwKCo2KyIjMkQyNjs9QEBAJjBGS0U+Sjk/QD3/2wBDAQsLCw8NDx0QEB09KSMpPT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT3/wAARCAAcACADAREAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQQGA//EACAQAAEEAgIDAQAAAAAAAAAAAAEAAgMRBCEFEhMiMRT/xAAYAQEBAQEBAAAAAAAAAAAAAAADAgEABP/EABgRAQEBAQEAAAAAAAAAAAAAAAABAhEh/9oADAMBAAIRAxEAPwDFw4/h+heXVe7M4Rxpq0hpYThPcKVO35+wXNgfOjEd0mFZxHA/3U1sN4jhQRlhFsgAXN4I5SE2UsFqBgfG7a6zrJ4ux80DVqblU0tZl9hoqOEzV3Jxto6VxFZTO9SaSZDpAyZ4d9V2DlpXEkcasodQ+LX/2Q==',
  hairLoss: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAcACADASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAABgQFAQMH/8QAIRAAAQQCAgIDAAAAAAAAAAAAAQACAwQFIRESBiITMTL/xAAVAQEBAAAAAAAAAAAAAAAAAAAEBf/EABoRAAIDAQEAAAAAAAAAAAAAAAARAQIDBBL/2gAMAwEAAhEDEQA/AAmS/ZC2Yit3kGlIyNU/KdKwwMHDxyFM9KCnWjGnjdQta3SbVI+GBUGCjAY1Kq7fVM57MB1ZqTlN3FEuJ6rGPoGOQaTa1Wj36qHFBGH/AEg2qijnZk3CwkAJRWiPUKtxUbQBpIYGDqkYwg3RLk//2Q==',
  highBloodPressure: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDABALDA4MChAODQ4SERATGCkbGBYWGDIkJh4pOzQ+PTo0OThBSV5QQUVZRjg5Um9TWWFkaWppP09ze3Jmel5naWX/2wBDARESEhgVGDAbGzBlQzlDZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWX/wAARCAAcACADAREAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAgMABAUB/8QAHhAAAwABBQEBAAAAAAAAAAAAAAECUQMEERRBEmH/xAAYAQADAQEAAAAAAAAAAAAAAAAAAQIDBP/EABgRAQEBAQEAAAAAAAAAAAAAAAABERIC/9oADAMBAAIRAxEAPwDQe5S9JPAPecej0Y53f0NLE7qyGjGXWu8kNMKrWeQ08KrcNejKwHaeQTiw4ZDo5LuXwB8q2pL5Kib5K+GNHLaaRm65AVKBWFVE4GmyBenOBxFj/9k=',
  hotFlashes: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDABALDA4MChAODQ4SERATGCkbGBYWGDIkJh4pOzQ+PTo0OThBSV5QQUVZRjg5Um9TWWFkaWppP09ze3Jmel5naWX/2wBDARESEhgVGDAbGzBlQzlDZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWX/wAARCAAcACADAREAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAwQFAgD/xAAfEAACAwACAgMAAAAAAAAAAAAAAQIDEQQSMWEhIkH/xAAXAQEBAQEAAAAAAAAAAAAAAAABAgAD/8QAGBEBAQEBAQAAAAAAAAAAAAAAAAERAhL/2gAMAwEAAhEDEQA/AK/InpVrSMUPGSrD0bcQjBIW6w1sSJWdmTrtOWoSUUZrHO/2bRIJVf7Np8p0bNZMVojn9ShpWd+S8g2iVcjf0CWrk9GIpiTfQpKbyJPt5JMG4jbfyC4//9k=',
  insomnia: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/+EAWEV4aWYAAE1NACoAAAAIAAIBEgADAAAAAQABAACHaQAEAAAAAQAAACYAAAAAAAOgAQADAAAAAQABAACgAgAEAAAAAQAAAe+gAwAEAAAAAQAAAaoAAAAA/9sAQwASDA0QDQsSEA4QFBMSFRssHRsYGBs2JykgLEA5REM/OT49R1BmV0dLYU09Pll5WmFpbXJzckVVfYZ8b4VmcHJu/9sAQwETFBQbFxs0HR00bkk+SW5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5u/8AAEQgAHAAgAwERAAIRAQMRAf/EABgAAAMBAQAAAAAAAAAAAAAAAAEDBQQC/8QAIBAAAgEEAgMBAAAAAAAAAAAAAAEDAgQREiExBRMUIv/EABcBAQEBAQAAAAAAAAAAAAAAAAABAgP/xAAXEQEBAQEAAAAAAAAAAAAAAAAAAREC/9oADAMBAAIRAxEAPwCdO+wRhkWWZaCPioCvYN8BGeZZY1YQ4sk1Qph/QFfx8PRWSPVszGumOvn46Jq4U49ahKYo2NSTRuViwqhLJxdztVqCMsySYhRtm1UdI5dP/9k=',
  sinusInfection: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAcACADASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAwQFBgH/xAAgEAABAwQCAwAAAAAAAAAAAAABAAIDBAURMRJREyEy/8QAGAEAAwEBAAAAAAAAAAAAAAAAAgMEAAX/xAAZEQADAQEBAAAAAAAAAAAAAAAAAQIRAxL/2gAMAwEAAhEDEQA/AGINqnTqdEQCnYpQ0bXEllmlFknFql3N/MELstWAMZSckoejbwHSSLi0HaILgCPTljH1EmfpGhnk7TZ5om9s0763J2uNrR2s86Z+NoXnf2s4Gyf/2Q==',
  std: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAcCAIAAACPoCp1AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAD/AP8A/6C9p5MAAAAHdElNRQfoBg0SExLGHVn2AAAEHklEQVRIx01WW44jORLjI0Jpz55hsT97/8vNLKob7Qzuh+QqJ4S0kAmTQTIUNv/93/+UVHbVvrusKvu92Q+76+p6rPW8+nld/7rW81rPta7utgUmeb3uX39eX79//+/r999fv/75+vXP1+8qqctld3m1u1xVXYfJZ+Nur6pr1dV1ldpq0YQQZgBmZuae+3Xf933fc98zk5lqq+3VXl2ra1WtdndVfaixu3YF9Whf5VVqsQQjRJDJZOae2dB3ZjJ3MlXl1b66rq5r9a6xu7qqyy6VZbusw1F1tS+rzSLEMJMkmcyd+86chRlmatmrfHU9Vz+ufqy+Vl9d3ccce3OorLK7tKwutlmCGCSTYG78QN/IEBFSXbrKj67Hqr8+OFbvJGTJlkVblspqqySLJogESIbZuAddGCFmah2L/Fz1XPXX1Y9rPb4JLFsSLe67JZMWRQgAMsnkZgYZ5hYOejFF1joK/Oh6rt7rsfpaHwSkBJIiLIoUQRAIJsDsXhJGiTDe6MIyqu1VO4ZvGj9XrVWrvM2RyAMKEgJIIEEwSJBTdUYcIyW0MCLNWqUt4jocepSv9lU+CkSK3OgIAQBEAAwCJhlihIg56MSIMAVVl36WtfnWe7MVkPyGBgAEQQDugBHhOGOiiDEyJCiq2vxcJZbZRosl1vFnO77BkxySQZjwEEBMMVsLTIFF1i68NrRQoonPdfLkQQUQYpO8TQsRMiZGKCECTUFDVJn27j9IECOCBPn+JsDTMQf97L4JAQLiXrQIb384oyrLPugkcHAHGUBAjvkfCWzMnQJwyiFI0kKGEBneYIhy0aL8UzUw2ellEoZvX4iAAXJ0KJvoMOxLVgAyJJnBHgXvJgeCBElmMokmYbJ1AXsyMEyQky+3zvNAmYSKqDAKplyHgNrl7DbZ4zFK5m3E/gyZPX8UYID7w6qQloIkEDIAS71HzMnoJAvMaUQg21KSyqmCRyd3TudoM+edggEUAHhP5B+OQxC8HSdDggYFGRB4Thgz4fB+vas/jYtAp5NTXqV92ZQogYLOV0KFCk05Km4aao8iZHjfOKVxm6j9hskAQFWXJX9z2LIgQ/tcmDJcUFENGfKOizso3XO/RIE6IQE7tn0Uq67VpLl/Q2Tt3xfLRRdt7o2a7sNBg8TphJt6jbay3QXnLIbAoNbqopqsHw5blssuuemWF930ogou0KROw80994uv0+kEhsci3ABRfV1NNtib5k1glVz+RN8b9bdLW4H0Z2SS76GSfZgAhKl1XQ2+OdhUb3dsq+XWQb9Yh4AqSADfCkovD3kTRJjJ++DhTl3XKrDxIYIqulRS6ShYqq1gwU0VdRQgM/efOSM9kwxmMMEEAVJrXd8EBX4TWJZa6h/0+napIO9OyYykIQe4E2aYm/uPQAaY/wNTXj0o99tfeQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNC0wNi0xM1QxNzo0NDowMiswMDowMK1epK8AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDUtMDRUMDE6NDk6MjUrMDA6MDAvRRskAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDI0LTA2LTEzVDE4OjE5OjE4KzAwOjAwQBWQ2wAAAABJRU5ErkJggg==',
  bloodTypeTest: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAA8KCw0LCQ8NDA0REA8RFiUYFhQUFi0gIhslNS84NzQvNDM7QlVIOz9QPzM0SmRLUFdaX2BfOUdob2dcblVdX1v/2wBDARARERYTFisYGCtbPTQ9W1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1v/wAARCAAcACADAREAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQQCBv/EAB4QAAIBBQEBAQAAAAAAAAAAAAABBAIDESFBMSIy/8QAFwEBAQEBAAAAAAAAAAAAAAAAAgEDAP/EABcRAQEBAQAAAAAAAAAAAAAAAAABEQL/2gAMAwEAAhEDEQA/AOUt222Z62wjGjZ4C05FVUXFPh0Wob1nDHGdjUazlg08MxI6wtEVZXH+PCpoyTG29F10iaJTtA0jcRLQoFIOlOgQjJdCTYK15FReBcZicHApFfgQjZvQVpy//9k=',
};

type ProductImage = {
  src: string;
  class: string;
};

type PackageType = {
  [packagingType: string]: ProductImage;
};

const packageImages: PackageType = {
  birthControlPackSky: {
    src: 'https://assets.lemonaidhealth.com/web/brochure/images/homepage-rebrand/3D-product-renderings/Birth_control_pack_Sky-100x220.png',
    class: 'birth_control_pack',
  },
  creamBottleGrass: {
    src: 'https://assets.lemonaidhealth.com/web/brochure/images/homepage-rebrand/3D-product-renderings/cream_bottle_grass-100x307.png',
    class: 'cream_bottle',
  },
  creamBottleSand: {
    src: 'https://assets.lemonaidhealth.com/web/brochure/images/homepage-rebrand/3D-product-renderings/cream_bottle_sand-100x307.png',
    class: 'cream_bottle',
  },
  creamBottleSky: {
    src: 'https://assets.lemonaidhealth.com/web/brochure/images/homepage-rebrand/3D-product-renderings/cream_bottle_sky-100x307.png',
    class: 'cream_bottle',
  },
  thickPillBottleGrass: {
    src: 'https://assets.lemonaidhealth.com/web/brochure/images/homepage-rebrand/3D-product-renderings/thick_pill_bottle_grass-100x183.png',
    class: 'thick_pill_bottle',
  },
  thickPillBottleSand: {
    src: 'https://assets.lemonaidhealth.com/web/brochure/images/homepage-rebrand/3D-product-renderings/thick_pill_bottle_sand-100x183.png',
    class: 'thick_pill_bottle',
  },
  thickPillBottleSky: {
    src: 'https://assets.lemonaidhealth.com/web/brochure/images/homepage-rebrand/3D-product-renderings/thick_pill_bottle_sky-100x183.png',
    class: 'thick_pill_bottle',

  },
  thinPillBottleGrass: {
    src: 'https://assets.lemonaidhealth.com/web/brochure/images/homepage-rebrand/3D-product-renderings/thin_pill_bottle_grass-100x205.png',
    class: 'thin_pill_bottle',

  },
  thinPillBottleSand: {
    src: 'https://assets.lemonaidhealth.com/web/brochure/images/homepage-rebrand/3D-product-renderings/thin_pill_bottle_sand-100x205.png',
    class: 'thin_pill_bottle',
  },
  thinPillBottleSky: {
    src: 'https://assets.lemonaidhealth.com/web/brochure/images/homepage-rebrand/3D-product-renderings/thin_pill_bottle_sky-100x205.png',
    class: 'thin_pill_bottle',
  },
};

type ServiceName =
  | 'Anxiety'
  | 'Depression'
  | 'Erectile Dysfunction'
  | 'Birth Control'
  | 'Insomnia'
  | 'UTI'
  | 'Genital Herpes'
  | 'Stop Smoking'
  | 'Premature Ejaculation'
  | 'Hair Loss'
  | 'Ella® Morning-After Pill'
  | 'Hot Flashes'
  | 'Cold Sore'
  | 'Sinus Infection'
  | 'Primary Care Complete'
  | 'Asthma'
  | 'Acid Reflux'
  | 'High Blood Pressure'
  | 'Acne'
  | 'Dark Spots'
  | 'STD-Testing'
  | 'A1C Blood Sugar Test'
  | 'Cholesterol Test'
  | 'Blood Type Test';

type Service = {
  title: ServiceName;
  images: {
    placeholder: string;
    desktop: string;
    mobile: string;
  };
  linkUrl: string;
  product: ProductImage | null;
};

const mentalHealthServices: Service[] = [
  {
    title: 'Anxiety',
    images: {
      placeholder: base64Placeholders.anxiety,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_anxiety_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_anxiety_495x426.jpg',
    },
    linkUrl: '/services/anxiety',
    product: packageImages.thickPillBottleGrass,
  },
  {
    title: 'Depression',
    images: {
      placeholder: base64Placeholders.depression,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_depression_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_depression_495x426.jpg',
    },
    linkUrl: '/services/depression',
    product: packageImages.thickPillBottleSand,
  },
  {
    title: 'Insomnia',
    images: {
      placeholder: base64Placeholders.insomnia,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_Insomnia_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_insomnia_495x426.jpg',
    },
    linkUrl: '/services/insomnia',
    product: packageImages.thickPillBottleGrass,
  },
  {
    title: 'Stop Smoking',
    images: {
      placeholder: base64Placeholders.stopSmoking,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_stop_smoking_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_Stop_Smoking_495x426.jpg',
    },
    linkUrl: '/services/stop-smoking',
    product: packageImages.thinPillBottleGrass,
  },
];

const mensHealthServices: Service[] = [
  {
    title: 'Erectile Dysfunction',
    images: {
      placeholder: base64Placeholders.ed,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_ED_750x540.png.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_ED_495x426.jpg',
    },
    linkUrl: '/ed-options',
    product: packageImages.thinPillBottleSky,
  },
  {
    title: 'Premature Ejaculation',
    images: {
      placeholder: base64Placeholders.pe,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_PE_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_PE_495x426.jpg',
    },
    linkUrl: '/services/premature-ejaculation',
    product: packageImages.thinPillBottleSky,
  },
  {
    title: 'Hair Loss',
    images: {
      placeholder: base64Placeholders.hairLoss,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_hair_loss_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_hair_loss_495x426.jpg',
    },
    linkUrl: '/services/hair-loss',
    product: packageImages.thinPillBottleSky,
  },
];

const womensHealthServices: Service[] = [
  {
    title: 'Ella® Morning-After Pill',
    images: {
      placeholder: base64Placeholders.afterPill,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Lemonaid_Morning-After_pill_Homepage_Tile_Desktop.png',
      mobile: '/homepage-rebrand/mobile-service-card-photos/Lemonaid_Morning-After_pill_Homepage_Tile_mobile.png',
    },
    linkUrl: '/ella-morning-after-pill',
    product: packageImages.birthControlPackSky,
  },
  {
    title: 'Birth Control',
    images: {
      placeholder: base64Placeholders.birthControl,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_birth_control_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_birth_control_495x426.jpg',
    },
    linkUrl: '/cp-options',
    product: packageImages.birthControlPackSky,
  },
  {
    title: 'UTI',
    images: {
      placeholder: base64Placeholders.uti,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_UTI_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_UTI_495x426.jpg',
    },
    linkUrl: '/services/urinary-tract-infection',
    product: packageImages.thinPillBottleGrass,
  },
  {
    title: 'Hot Flashes',
    images: {
      placeholder: base64Placeholders.hotFlashes,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_hot_flashes_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_hot_flashes_495x426.jpg',
    },
    linkUrl: '/services/hot-flashes',
    product: packageImages.thinPillBottleGrass,
  },
];

const generalHealthServices: Service[] = [
  {
    title: 'Cold Sore',
    images: {
      placeholder: base64Placeholders.coldSore,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_Cold_sore_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_cold_sore_495x426.jpg',
    },
    linkUrl: '/services/cold-sore',
    product: packageImages.thinPillBottleGrass,
  },
  {
    title: 'Genital Herpes',
    images: {
      placeholder: base64Placeholders.genetalHerpes,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_Genetal_herpes_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_Genetal_herpes_495x426.jpg',
    },
    linkUrl: '/services/genital-herpes',
    product: packageImages.thinPillBottleSky,
  },
  {
    title: 'Sinus Infection',
    images: {
      placeholder: base64Placeholders.sinusInfection,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_sinus_infection_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_sinus_infection_495x426.jpg',
    },
    linkUrl: '/services/sinus-infection',
    product: packageImages.thinPillBottleSky,
  },
  {
    title: 'Primary Care Complete',
    images: {
      placeholder: base64Placeholders.steadyMD,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_image_Steady_MD_desktop_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photo_Steady_MD_mobile_495x426.jpg',
    },
    linkUrl: '/services/primary-care',
    product: null,
  },
  {
    title: 'Asthma',
    images: {
      placeholder: base64Placeholders.asthma,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_asthma_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_asthma_495x426.jpg',
    },
    linkUrl: '/services/asthma',
    product: packageImages.thickPillBottleGrass,
  },
  {
    title: 'Acid Reflux',
    images: {
      placeholder: base64Placeholders.acidReflux,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_acid_reflux_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_acid_reflux_495x426.jpg',
    },
    linkUrl: '/services/acid-reflux',
    product: packageImages.thickPillBottleSand,
  },
  {
    title: 'High Blood Pressure',
    images: {
      placeholder: base64Placeholders.highBloodPressure,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_high_blood_pressure_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_high_blood_pressure_495x426.jpg',
    },
    linkUrl: '/services/hypertension',
    product: packageImages.thickPillBottleSky,
  },
];

const skinHealthServices: Service[] = [
  {
    title: 'Acne',
    images: {
      placeholder: base64Placeholders.acne,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_acne_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_acne_495x426.jpg',
    },
    linkUrl: '/services/acne',
    product: packageImages.creamBottleSky,
  },
  {
    title: 'Dark Spots',
    images: {
      placeholder: base64Placeholders.darkSpots,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_dark_spots_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_dark_spots_495x426.jpg',
    },
    linkUrl: '/services/dark-spots',
    product: packageImages.creamBottleGrass,
  },
];

const testingServices: Service[] = [
  {
    title: 'STD-Testing',
    images: {
      placeholder: base64Placeholders.std,
      desktop: '/lemonaid-STD-testing-desktop-750x540.png',
      mobile: '/lemonaid-STD-testing-mobile-495x426.png',
    },
    linkUrl: '/services/std-testing',
    product: null,
  },
  {
    title: 'A1C Blood Sugar Test',
    images: {
      placeholder: base64Placeholders.a1c,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_A1C_blood_sugar_test_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_A1C_blood_sugar_test_495x426.jpg',
    },
    linkUrl: '/services/a1c-blood-test',
    product: null,
  },
  {
    title: 'Cholesterol Test',
    images: {
      placeholder: base64Placeholders.cholesterol,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_cholesterol_test_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_cholesterol_test_495x426.jpg',
    },
    linkUrl: '/services/cholesterol',
    product: null,
  },
  {
    title: 'Blood Type Test',
    images: {
      placeholder: base64Placeholders.bloodTypeTest,
      desktop: '/homepage-rebrand/desktop-service-card-photos/Homepage_photos_desktop_blood_type_test_750x540.jpg',
      mobile: '/homepage-rebrand/mobile-service-card-photos/homepage_photos_mobile_blood_type_test_495x426.jpg',
    },
    linkUrl: '/services/blood-type-test',
    product: null,
  },
];

const popularServices: Service[] = [
  mentalHealthServices[0], // Anxiety
  mentalHealthServices[1], // Depression
  mensHealthServices[0],   // Erectile Dysfunction
  womensHealthServices[1], // Birth Control
  mentalHealthServices[2], // Insomnia
  womensHealthServices[2], // UTI
  generalHealthServices[1], // Genital Herpes
];

export enum ServiceCategory {
  Popular = 'Popular',
  MentalHealth = 'Mental Health',
  MensHealth = 'Mens Health',
  WomensHealth = 'Womens Health',
  GeneralHealth = 'General Health',
  Skin = 'Skin',
  Testing = 'Testing',
}

export const services: { [key in ServiceCategory]: Service[] } = {
  [ServiceCategory.Popular]: popularServices,
  [ServiceCategory.MentalHealth]: mentalHealthServices,
  [ServiceCategory.MensHealth]: mensHealthServices,
  [ServiceCategory.WomensHealth]: womensHealthServices,
  [ServiceCategory.GeneralHealth]: generalHealthServices,
  [ServiceCategory.Skin]: skinHealthServices,
  [ServiceCategory.Testing]: testingServices,
};
