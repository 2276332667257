import { getCookie, setCookie } from './mongo-tracking.js'
import { removeDisplay } from './utils.js';

// This is to apply anti-flickering in GO pages

// We cache search parameters including UTM parameters

if (window.location.search) {
  sessionStorage.gtmParameters = window.location.search;
}

// window.main_js_setup;

/*
=============================================================================
Document is ready
=============================================================================
*/

window.onload = function () {

  // HERE WE TRACK OPTIMIZELY DATA ON GA
  optimizely_ga_tracking();

  // HERE WE TRACK EVERY PAGE HIT ON GA
  auth_flow();

  // FIX FOR THE ANTI-FLICKERING
  removeDisplay('.sidebar-menu');
  removeDisplay('.about-us-dropdown-menu');

  // HERE WE TRACK EVERY PAGE HIT ON MONGO DB
  window.trackScreen();

  window.main_js_setup();

  if (window.location.pathname === '/') {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 500);
  }

  var cookieEnabled = window.navigator.cookieEnabled;
  if (!cookieEnabled) {
    var cookies = window.document.getElementById('cookiesMessage');
    cookies.style.display = 'block';
  }
}

/*
=============================================================================
*/
var serviceCodes = {
  '/cialis-daily-delivery': ['EDC', 53],
  '/cp-options': ['CP', 1],
  '/ed-options': ['ED', 3],
  '/erectile-dysfunction-online-doctor': ['EDA', 41],
  '/erectile-dysfunction-USA-thank-you': ['ED', 3],
  '/erectile-dysfunction-USA': ['ED', 3],
  '/faq': [''],
  '/finasteride-buy': ['HL', 6],
  '/index': [''],
  '/learn-more-about-cialis-delivery': ['EDC', 53],
  '/learn-more-about-UTI-Scanwell': ['UTI-S', 52],
  '/meet-our-physicians': [''],
  '/online-doctor-USA-thank-you': [''],
  '/online-doctor-USA': [''],
  '/online-prescription-USA-thank-you': [''],
  '/online-prescription-USA': [''],
  '/our-mission': [''],
  '/prescription-policy': [''],
  '/refills-thankyou': [''],
  '/sildenafil-buy': ['EDA', 41],
  '/singlecare-erectile-dysfunction': ['EDSC', 56],
  '/UTI-Scanwell': ['UTI-S', 52],
  '/browsernotsupported/notsupported': [''],
  '/drug/5-ways-to': ['EDA', 41],
  '/drug/amitriptyline': ['MIG', 55],
  '/drug/bupropion': ['MH', 43],
  '/drug/cialis-daily-compare-experience': ['EDC', 53],
  '/drug/cialis-daily-compare-price': ['EDC', 53],
  '/drug/cialis-daily': ['EDC', 53],
  '/drug/cialis': ['ED', 3],
  '/drug/errin': ['CP', 1],
  '/drug/escitalopram': ['MH', 43],
  '/drug/finasteride': ['HLX', 39],
  '/drug/fluoxetine': ['MH', 43],
  '/drug/get-viagra-today': ['EDA', 41],
  '/drug/lexapro-now': ['MH', 43],
  '/drug/lexapro': ['MH', 43],
  '/drug/ortho-tri-cyclen-lo': ['CP', 1],
  '/drug/prozac': ['MH', 43],
  '/drug/sertraline': ['MH', 43],
  '/drug/sildenafil': ['EDA', 41],
  '/drug/sprintec': ['CP', 1],
  '/drug/stendra': ['EDA', 41],
  '/drug/stendra-e': ['EDA', 41],
  '/drug/tadalafil': ['ED', 3],
  '/drug/viagra-compare': ['EDA', 41],
  '/drug/viagra-low-cost': ['EDA', 41],
  '/drug/viagra': ['EDA', 41],
  '/drug/wellbutrin': ['MH', 43],
  '/drug/yasmin': ['CP', 1],
  '/drug/zoloft': ['MH', 43],
  '/ella-morning-after-pill': ['EC', 85],
  '/faqs/a1c-blood-sugar-test': [''],
  '/faqs/about-lemonaid': [''],
  '/faqs/acid-reflux': [''],
  '/faqs/acne': [''],
  '/faqs/asthma': [''],
  '/faqs/birth-control-pill': [''],
  '/faqs/blood-type-test': [''],
  '/faqs/cholesterol': [''],
  '/faqs/cold-sore': [''],
  '/faqs/dark-spots': [''],
  '/faqs/depression-anxiety': [''],
  '/faqs/erectile-dysfunction-eda': [''],
  '/faqs/erectile-dysfunction': [''],
  '/faqs/genital-herpes': [''],
  '/faqs/hair-loss': [''],
  '/faqs/health-and-wellness-coaching': [''],
  '/faqs/high-blood-pressure': [''],
  '/faqs/hot-flashes': [''],
  '/faqs/how-much-does-it-cost': [''],
  '/faqs/how-our-service-works': [''],
  '/faqs/how-we-can-help': [''],
  '/faqs/hypothyroidism': [''],
  '/faqs/insomnia': [''],
  '/faqs/migraine': [''],
  '/faqs/premature-ejaculation': [''],
  '/faqs/sinus-infection': [''],
  '/faqs/std': [''],
  '/faqs/stop-smoking': [''],
  '/faqs/uti': [''],
  '/genetic-report-consultation': ['GEN', 83],
  '/landing/birth-control-new-york': ['CP', 1],
  '/landing/birth-control-pills-errin': ['CP', 1],
  '/landing/birth-control-pills-ortho-tri-cyclen-lo': ['CP', 1],
  '/landing/birth-control-pills-sprintec': ['CP', 1],
  '/landing/birth-control-slv': ['CP', 1],
  '/landing/come-back': [''],
  '/landing/erectile-dysfunction-monthly-shipping-slv': ['ED', 3],
  '/landing/erectile-dysfunction-monthly-shipping': ['ED', 3],
  '/landing/erectile-dysfunction-revatio': ['ED', 3],
  '/landing/erectile-dysfunction-viagra': ['ED', 3],
  '/landing/erectile-dysfunction': ['ED', 3],
  '/landing/get-started-ed': ['EDX', 38],
  '/landing/goodrx-mh': ['MH', 43],
  '/landing/goodrx': ['EDA', 41],
  '/landing/learn-more-about-cp-v2': ['CP', 1],
  '/landing/learn-more-about-ed-v2': ['ED', 3],
  '/landing/learn-more-about-ed': ['ED', 3],
  '/landing/learn-more-about-hl-v2': ['HLX', 39],
  '/landing/learn-more-about-pe-v2': ['PE', 42],
  '/landing/learn-more-about-pe': ['PE', 42],
  '/landing/learn-more-about-sildenafil-delivery-v2': ['EDA', 41],
  '/landing/learn-more-about-sildenafil-delivery': ['EDA', 41],
  '/landing/mentalhealth': ['MH', 43],
  '/landing/mh-free-shipping': ['MH', 43],
  '/landing/mh-personalized-treatment': ['MH', 43],
  '/landing/sildenafil-delivery-faq': [''],
  '/landing/sildenafil-delivery': ['EDA', 41],
  '/landing/sildenafil-delivery-v2': ['EDA', 41],
  '/landing/uti-slv': ['UTI', 5],
  '/landing/valentines-day': ['EDA', 41],
  '/landing/viagra-california-slv': ['EDA', 41],
  '/landing/viagra-slv': ['EDA', 41],
  '/legals/consent-to-telehealth': [''],
  '/legals/prescription-policy': [''],
  '/legals/privacy-policy': [''],
  '/legals/terms-of-use': [''],
  '/pm/acne-almirall': ['ACNE', 13],
  '/pm/mental-health-free-assessment': ['MH-A', 72],
  '/pm/treat-erectile-dysfunction': ['ED', 3],
  '/prescription/birth-control-delivery-slv': ['CP', 1],
  '/prescription/birth-control-delivery': ['CP', 1],
  '/prescription/hair-loss-delivery-slv': ['HLX', 39],
  '/prescription/hair-loss-delivery': ['HLX', 39],
  '/prescription/learn-more-about-cp': ['CP', 1],
  '/prescription/learn-more-about-hl': ['HLX', 39],
  '/services/a1c-blood-test': ['A1C', 27],
  '/services/acid-reflux': ['GERD', 7],
  '/services/acne-learn-more': ['ACNE', 13],
  '/services/acne': ['ACNE', 13],
  '/services/anxiety-learn-more': ['MH', 43],
  '/services/anxiety': ['MH', 43],
  '/services/asthma': ['ASM', 76],
  '/services/birth-control-patch': ['CP', 1],
  '/services/birth-control-pills': ['CP', 1],
  '/services/birth-control-ring': ['CP', 1],
  '/services/blood-type-test': ['ABO', 29],
  '/services/cholesterol': ['LIPIDS', 18],
  '/services/cold-sore': ['HSV1', 32],
  '/services/compare-ed-meds-mobile': ['ED', 3],
  '/services/covid-19-lab-test': ['COV-Q', 65],
  '/services/dark-spots-learn-more': ['SKDS', 60],
  '/services/dark-spots': ['SKDS', 60],
  '/services/depression-anxiety': ['MH', 43],
  '/services/seasonal-depression': ['MH', 43],
  '/services/depression-learn-more': ['MH', 43],
  '/services/depression': ['MH', 43],
  '/services/erectile-dysfunction': ['ED', 3],
  '/services/genital-herpes': ['GH', 46],
  '/services/hair-loss': ['HLX', 39],
  '/services/hl-choices': ['HL', 6],
  '/services/hot-flashes': ['HRT', 57],
  '/services/hypertension': ['HTN', 69],
  '/services/hypothyroidism': ['TSH', 49],
  '/services/insomnia': ['INS', 73],
  '/services/learn-more-about-migraine': ['MIG', 55],
  '/services/migraine': ['MIG', 55],
  '/services/premature-ejaculation': ['PE', 42],
  '/services/primary-care': ['ANY', 64],
  '/services/sinus-infection': ['SI', 11],
  '/services/std-testing': ['STD', 21],
  '/services/stop-smoking-delivery': ['SC', 31],
  '/services/stop-smoking': ['SC', 31],
  '/services/urinary-tract-infection': ['UTI', 5],
  '/services/virtual-doctor-visit': ['ANY', 64],
};

window.pages_showing_dynamic_reviews = [
  '/faq',
  '/drug/errin',
  '/drug/sprintec',
  '/drug/ortho-tri-cyclen-lo',
  '/landing/birth-control-new-york',
  '/landing/birth-control-pills-errin',
  '/landing/birth-control-pills-ortho-tri-cyclen-lo',
  '/landing/birth-control-pills-sprintec',
  '/landing/birth-control-slv',
  '/landing/goodrx',
  '/landing/uti-slv',
  '/landing/viagra-california-slv',
  '/landing/viagra-slv',
  '/legals/prescription-policy',
  '/legals/terms-of-use',
  '/prescription-policy',
  '/services/a1c-blood-test',
  '/services/acid-reflux',
  '/services/birth-control-patch',
  '/services/birth-control-pills',
  '/services/birth-control-ring',
  '/services/blood-type-test',
  '/services/cholesterol',
  '/services/cold-sore',
  '/services/sinus-infection',
  '/services/std-testing',
  '/services/urinary-tract-infection',
];

window.pages_showing_dynamic_new_design_reviews = ['/'];

window.pages_showing_photo_carousel = ['/our-story', '/careers'];

window.pages_showing_pets_carousel = ['/meet-our-lemons'];

window.pages_showing_sponsors_carousel = ['/our-story'];

// Carousel replaced for now
// To be uncommented if/when it is reintroduced
//
// window.pages_showing_cards_carousel = [
//   '/genetic-report-consultation'
// ];

window.clinic_url = CLINIC_URL;

window.faqLinkList = {};
window.hash = window.location.hash;
window.page = window.location.pathname;

var slvStatesNoED =
  'AL, AK, AR, AZ, CA, CO, CT, DC, DE, FL, HI, IA, ID, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, NE, ND, NH, NJ, NM, NV, OH, OK, OR, RI, SC, SD, TN, TX, UT, VA, VT, WI, WV or WY';

// For ED
// Do not include: South Carolina(SC) / Minnesota(MN)
var slvStatesEDOnly =
  'AL, AK, AR, AZ, CA, CO, CT, DC, DE, FL, HI, IA, ID, IN, KS, KY, LA, MA, MD, ME, MI, MO, MS, MT, NC, NE, ND, NH, NJ, NM, NV, OH, OK, OR, RI, SD, TN, TX, UT, VA, VT, WI, WV or WY';

var slvStatesNoEDText =
  'Alabama, Alaska, Arizona, Arkansas, California, Colorado, Connecticut, Delaware, District of Columbia, Florida, Idaho, Indiana, Iowa, Kansas, Kentucky, Louisiana, Maine, Maryland, Massachusetts, Michigan, Minnesota, Mississippi, Missouri, Montana, Nebraska, Nevada, New Hampshire, New Jersey, New Mexico, North Carolina, North Dakota, Ohio, Oklahoma, Oregon, Rhode Island, South Carolina, South Dakota, Tennessee, Texas, Utah, Vermont, Virginia, Wisconsin or Wyoming';

// For ED
// Do not include: South Carolina(SC) / Minnesota(MN)
var slvStatesEDOnlyText =
  'Alabama, Alaska, Arizona, Arkansas, California, Colorado, Connecticut, Delaware, District of Columbia, Florida, Idaho, Indiana, Iowa, Kansas, Kentucky, Louisiana, Maine, Maryland, Massachusetts, Michigan, Mississippi, Missouri, Montana, Nebraska, Nevada, New Hampshire, New Jersey, New Mexico, North Carolina, North Dakota, Ohio, Oklahoma, Oregon, Rhode Island, South Dakota, Tennessee, Texas, Utah, Vermont, Virginia, Wisconsin or Wyoming';

var serviceAvailableStatesGeneral =
  'We can help you if you’re in AL, AK, AZ, AR, CA, CO, CT, DC, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND, NE, NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV or WY.';

var serviceAvailableStatesTreatment =
  'Available in AL, AK, AZ, AR, CA, CO, CT, DC, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, ME, MD, MI, MN, MO, MS, MT, NC, ND, NE, NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV and WY. Other states coming soon.';

var serviceAvailableStatesLabs =
  'Available in AL, AK, AR, CA, CO, CT, DC, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND, NE, NH, NM, NV, OH, OR, PA, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV and WY. Not available in AZ, NJ, NY, OK and RI.';

var sprintecStates =
  'Sprintec prescriptions available in AL, AK, AZ, AR, CA, CO, CT, DC, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, NE, ND, NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV and WY. Bringing Sprintec to other states soon!';

var orthoStates =
  'Prescriptions for Ortho-Tri Cyclen lo available in AL, AK, AZ, AR, CA, CO, CT, DC, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, NE, ND, NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV and WY. Other states coming soon!';

var errinStates =
  'Prescriptions available in AL, AK, AZ, AR, CA, CO, CT, DC, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, NE, ND, NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV and WY. We’ll be making it simple to get Errin in other states soon.';

// For ED
// Not available in SC, MN
var edStates =
  'Available in AL, AK, AZ, AR, CA, CO, CT, DC, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MO, MS, MT, NC, NE, ND, NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SD, TN, TX, UT, VA, VT, WA, WI, WV and WY. Not available in SC and MN.';

// For Blood Test
var bloodTestStates =
  'Available in AL, AK, AZ, AR, CA, CO, CT, DC, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, NE, ND, NH, NM, NV, OH, OK, OR, PA, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV and WY.';

// For STD Test
// Available in NY but not in AZ, NJ, OK, RI
var stdLabsStates =
  'Available in AL, AK, AR, CA, CO, CT, DC, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, NE, ND, NH, NM, NV, NY, OH, OR, PA, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV and WY. Not available in AZ, NJ, OK and RI.';

// PRICES
var serviceFee = '$25';
var edocpserviceFee = '$25';
var sinusInfectionFee = '$45';
var cholesterolLabFee = '$17.81';
var cholesterolVisitAndLabFee = '$42.81';
var a1cLabFee = '$9.16';
var a1cVisitAndLabFee = '$34.16';
var aboLabFee = '$11.11';
var aboVisitAndLabFee = '$36.11';
var utiVisitFee = '$25';
var stdVisitFee = '$25';
var stdLabFee = '$28.16';
var stdVisitAndLabFee = '$53.16';
var stdVisitAndLabFeeUnder = '$55';

var htmlText;

//******** ENDPOINTS *********//

window.cancellation_api = PIMS_API_URL + 'content/web/cancellationPolicy';
window.consent_telehealth_api = PIMS_API_URL + 'content/web/consent';
window.consent_data_processing_api = PIMS_API_URL + 'content/web/sensitiveDataProcessing';
window.cookie_api = PIMS_API_URL + 'content/web/cookie';
window.privacy_api = PIMS_API_URL + 'content/web/privacy';
window.refund_api = PIMS_API_URL + 'content/web/refundPolicy';
window.terms_api = PIMS_API_URL + 'content/web/terms';
// window.app_reviews_api = PIMS_API_URL + 'reviews?target_platform=web';
window.app_reviews_api = PIMS_API_URL + 'content/web/brochureReviews';
window.request_mailorder_refills_api = PIMS_API_URL + 'nologin/mailorders/:mail_order_id/fills';

//******** FUNCTIONS *********//
var serializeQuery = function (obj) {
  if (!obj) {
    return '';
  }

  var parameters = '';

  for (var key in obj) {
    const value = obj[key];
    if (value === null || value === undefined) {
      continue;
    }
    const delimiter = !parameters.length ? '?' : '&';
    parameters += delimiter + key + '=' + value;
  }

  return parameters;
};

//******** Google Analytics Functions *********//

function auth_flow() {

  var brochurePath = window.location.pathname;

  if (brochurePath.includes('.html')) {
    brochurePath = brochurePath.substring(0, brochurePath.lastIndexOf('.'));
  }

  window.dataLayer = window.dataLayer || [];

  if (brochurePath in serviceCodes) {
    window.dataLayer.push({
      event: 'auth_flow',
      eventCategory: 'auth_flow', // yes exact same
      eventAction: 'brochure_page', // new event Action type for Brochure Pages
      eventLabel: [serviceCodes[brochurePath][0]],
      signed_in: [0], // bool
      new_account: ['na'], // na if na at this step
      product_name: ['na'], // if the user has specified the product name, 'na' otherwise
      dosage: ['na'], // if the user has specified the product dosage, 'na' otherwise
      pharmacy_options: ['na'], // if the user has specified the pharm  'child_service': [child_service], // for flows with nested services. If this hit is on a nested service, which specific path did they take
      child_service: ['na'], // for flows with nested services. If this hit is on a nested service, which specific path did they take
      subscription: [null], // bool, depending on whether the user is on a subscription for this plan. Just null if unknown or na
    });

    window.dataLayer.push({
      la_serviceId: serviceCodes[brochurePath][1] || null,
    });
  }
}

//******** Content Loaded *********//
document.addEventListener('DOMContentLoaded', function () {
  const servicesButton = document.querySelector('.mobile-services-menu');
  if (servicesButton) {
    servicesButton.addEventListener('click', function () {
      toggleAriaExpandendAttribute(this);
    });
  }

  const sidebarServicesButton = document.querySelector('.sidebar-menu__services-menu-link');
  if (sidebarServicesButton) {
    sidebarServicesButton.addEventListener('click', function () {
      toggleAriaExpandendAttribute(this);
    });
  }

  const sidebarAboutUsButton = document.querySelector('.sidebar-menu__about-us-link');
  if (sidebarAboutUsButton) {
    sidebarAboutUsButton.addEventListener('click', function () {
      toggleAriaExpandendAttribute(this);
    });
  }

  const sidebarAccountButton = document.querySelector('.sidebar-menu__account-link');
  if (sidebarAccountButton) {
    sidebarAccountButton.addEventListener('click', function () {
      toggleAriaExpandendAttribute(this);
    });
  }

  function toggleAriaExpandendAttribute(el) {
    const ariaExpanded = el.getAttribute('aria-expanded');
    ariaExpanded === 'false' ? el.setAttribute('aria-expanded', 'true') : el.setAttribute('aria-expanded', 'false');
  }

  const servicesBtn = document.querySelector('#services-dropdown-btn');
  const servicesContainer = document.querySelector('#services-dropdown-container');
  const aboutUsBtn = document.querySelector('#about-us-dropdown-btn');
  const aboutUsContainer = document.querySelector('.about-us-dropdown-menu');
  const navMenu = document.querySelector('.header-menu');
  var lastOpenedDropdown = null;

  function toggleArrowIcon(button) {
    const arrow = button.querySelector('.header-menu__caret');
    arrow.classList.toggle('icon-rebrand-caret-down');
    arrow.classList.toggle('icon-rebrand-caret-up');
  }

  function resetArrowIcon(button) {
    const arrow = button.querySelector('.header-menu__caret');
    if (arrow.classList.contains('icon-rebrand-caret-up')) {
      toggleArrowIcon(button);
    }
  }

  function closeAboutUsDropdown() {
    aboutUsContainer.classList.remove('open');
    aboutUsBtn.classList.remove('header-menu__link--active');
    resetArrowIcon(aboutUsBtn);
    aboutUsBtn.setAttribute('aria-expanded', false);
  }

  function closeServicesDropdown() {
    servicesContainer.classList.remove('open');
    servicesBtn.classList.remove('header-menu__link--active');
    resetArrowIcon(servicesBtn);
    servicesBtn.setAttribute('aria-expanded', false);
  }

  function closeDropdowns() {
    closeServicesDropdown();
    closeAboutUsDropdown();
  }

  function onOutsideDropdownClicked() {
    closeDropdowns();
    document.removeEventListener('click', onOutsideDropdownClicked);
    navMenu.removeEventListener('focus', accessibilityServicesMenu, true);
    navMenu.removeEventListener('focus', accessibilityAboutUsMenu, true);
    deleteServicesMenuDummyItems();
    deleteAboutUsDummyItems();
    navMenu.removeEventListener('focus', accessibilityAboutUsMenu, true);
    aboutUsContainer.removeEventListener('focus', accessibilityAboutUsMenu, true);
  }

  function onDropdownContainerClicked(container, button) {
    if (lastOpenedDropdown && lastOpenedDropdown !== container) {
      closeDropdowns();
    }

    lastOpenedDropdown = container;
    toggleAriaExpandendAttribute(button);
    toggleArrowIcon(button);
    button.classList.toggle('header-menu__link--active');

    if (!container.classList.contains('open')) {
      container.classList.add('open');
      document.addEventListener('click', onOutsideDropdownClicked);
      event.stopPropagation();
    }

    button.focus();
  }

  if (servicesBtn) {
    servicesBtn.addEventListener('click', function () {
      onDropdownContainerClicked(servicesContainer, servicesBtn);
      navMenu.addEventListener('focus', accessibilityServicesMenu, true);
    });
  }

  if (aboutUsBtn) {
    aboutUsBtn.addEventListener('click', function () {
      navMenu.addEventListener('focus', accessibilityAboutUsMenu, true);
      aboutUsContainer.addEventListener('focus', accessibilityAboutUsMenu, true);
      onDropdownContainerClicked(aboutUsContainer, aboutUsBtn);
      accessibilityAboutUsMenu();
    });
  }

  const burgerBtn = document.querySelector('#burger-btn');
  const sidebarMenu = document.querySelector('.sidebar-menu');

  const toggleBurgerBtnAriaLabel = function () {
    const closeButtonLabel = 'Close dialog';
    const burgerBtnLabel = 'Navigation Menu - Opens a Simulated Dialog';
    if (burgerBtn.getAttribute('aria-label') === burgerBtnLabel) {
      burgerBtn.setAttribute('aria-label', closeButtonLabel);
    } else {
      burgerBtn.setAttribute('aria-label', burgerBtnLabel);
    }
  };

  const onBurgerBtnClicked = function () {
    burgerBtn.classList.toggle('icon-rebrand-close');
    burgerBtn.classList.toggle('icon-rebrand-list');
    sidebarMenu.classList.toggle('open');
    document.body.classList.toggle('hide-body-y-scroll');
    document.documentElement.classList.toggle('hide-body-y-scroll');
    toggleBurgerBtnAriaLabel();
  };

  const onOutsideSidebarClicked = function (event) {
    if (!event.target.closest('.sidebar-menu')) {
      onBurgerBtnClicked();
      document.removeEventListener('click', onOutsideSidebarClicked);
      navMenu.removeEventListener('focus', accessibilityMobileMenu, true);
      sidebarMenu.removeEventListener('focus', accessibilityMobileMenu, true);
      deleteMobileMenuDummyItems();
    }
  };

  if (burgerBtn) {
    burgerBtn.addEventListener('click', function (event) {
      if (sidebarMenu.classList.contains('open')) {
        document.removeEventListener('click', onOutsideSidebarClicked);
        navMenu.removeEventListener('focus', accessibilityMobileMenu, true);
        sidebarMenu.removeEventListener('focus', accessibilityMobileMenu, true);
        deleteMobileMenuDummyItems();
      } else {
        document.addEventListener('click', onOutsideSidebarClicked);
        navMenu.addEventListener('focus', accessibilityMobileMenu, true);
        sidebarMenu.addEventListener('focus', accessibilityMobileMenu, true);
      }
      onBurgerBtnClicked();
      event.stopPropagation();
    });
  }

  function deleteServicesMenuDummyItems() {
    const beforeMenu = document.getElementById('dummyItemBeforeServices');
    const afterMenu = document.getElementById('dummyItemAfterServices');
    if (beforeMenu) {
      beforeMenu.remove();
      afterMenu.remove();
    }
  }

  function accessibilityServicesMenu() {
    const dropdownMenu = document.querySelector('.services-dropdown-menu'),
      dropdownBtn = document.querySelector('#services-dropdown-btn');
    const allLinks = dropdownMenu.getElementsByTagName('a'),
      lastLinkModal = allLinks[allLinks.length - 1],
      statusServicesModal = document.getElementById('services-dropdown-container').classList.contains('open');

    if (document.getElementById('dummyItemBeforeServices') == null) {
      dropdownBtn.insertAdjacentHTML('beforeBegin', '<div id="dummyItemBeforeServices" tabindex="1+"></div>');
      dropdownMenu.insertAdjacentHTML('beforeEnd', '<div id="dummyItemAfterServices" tabindex="1+"></div>');
    }

    if (!statusServicesModal && document.getElementById('dummyItemBeforeServices') !== null) {
      deleteServicesMenuDummyItems();
      navMenu.removeEventListener('focus', accessibilityServicesMenu, true);
    }

    if (document.activeElement.id === 'dummyItemBeforeServices') {
      lastLinkModal.focus();
    }

    if (document.activeElement.id === 'dummyItemAfterServices') {
      dropdownBtn.focus();
    }
  }

  function deleteAboutUsDummyItems() {
    const beforeButton = document.getElementById('dummyItemBeforeAboutButton');
    const afterButton = document.getElementById('dummyItemAfterAboutButton');
    const beforeList = document.getElementById('dummyItemBeforeAboutList');
    const afterList = document.getElementById('dummyItemAfterAboutList');
    if (beforeButton) {
      beforeButton.remove();
      afterButton.remove();
      beforeList.remove();
      afterList.remove();
    }
  }

  function accessibilityAboutUsMenu() {
    const dropdownMenu = document.querySelector('.about-us-dropdown-menu'),
      dropdownBtn = document.querySelector('#about-us-dropdown-btn');
    const allLinks = dropdownMenu.getElementsByTagName('a'),
      lastLinkModal = allLinks[allLinks.length - 1],
      statusServicesModal = dropdownMenu.classList.contains('open');

    if (document.getElementById('dummyItemBeforeAboutButton') == null) {
      dropdownBtn.insertAdjacentHTML('beforeBegin', '<div id="dummyItemBeforeAboutButton" tabindex="1+"></div>');
      dropdownBtn.insertAdjacentHTML('afterEnd', '<div id="dummyItemAfterAboutButton" tabindex="1+"></div>');
      dropdownMenu.insertAdjacentHTML('afterBegin', '<div id="dummyItemBeforeAboutList" tabindex="1+"></div>');
      dropdownMenu.insertAdjacentHTML('beforeEnd', '<div id="dummyItemAfterAboutList" tabindex="1+"></div>');
    }

    if (!statusServicesModal && document.getElementById('dummyItemBeforeAboutButton') !== null) {
      deleteAboutUsDummyItems();
      navMenu.removeEventListener('focus', accessibilityAboutUsMenu, true);
      aboutUsContainer.removeEventListener('focus', accessibilityAboutUsMenu, true);
    }

    if (document.activeElement.id === 'dummyItemBeforeAboutButton') {
      lastLinkModal.focus();
    }

    if (document.activeElement.id === 'dummyItemAfterAboutButton') {
      allLinks[0].focus();
    }

    if (document.activeElement.id === 'dummyItemBeforeAboutList') {
      dropdownBtn.focus();
    }

    if (document.activeElement.id === 'dummyItemAfterAboutList') {
      dropdownBtn.focus();
    }
  }

  function deleteMobileMenuDummyItems() {
    const beforeMenu = document.getElementById('dummyItemBeforeMenu');
    const afterMenu = document.getElementById('dummyItemAfterMenu');
    if (beforeMenu) {
      beforeMenu.remove();
      afterMenu.remove();
    }
  }

  function accessibilityMobileMenu() {
    const closeButton = document.querySelector('.header__burger-btn');
    const menu = document.querySelector('.sidebar-menu');
    const links = menu.querySelectorAll('a');
    var lastLink;

    for (var i = links.length - 1; i >= 0; i -= 1) {
      if (links[i].offsetHeight) {
        lastLink = links[i];
        break;
      }
    }

    if (document.getElementById('dummyItemBeforeMenu') == null) {
      closeButton.insertAdjacentHTML('beforeBegin', '<div id="dummyItemBeforeMenu" tabindex="1+"></div>');
      menu.insertAdjacentHTML('beforeEnd', '<div id="dummyItemAfterMenu" tabindex="1+"></div>');
    }

    if (document.activeElement.id === 'dummyItemBeforeMenu') {
      lastLink.focus();
    }

    if (document.activeElement.id === 'dummyItemAfterMenu') {
      closeButton.focus();
    }
  }

  document.addEventListener('keydown', function (event) {
    const openedMobileMenu = document.querySelector('.sidebar-menu.open');

    if (event.keyCode === 27 && openedMobileMenu) {
      const closeBtn = document.querySelector('.header__burger-btn');
      closeBtn.click();
    }

    if (event.keyCode === 38 || event.keyCode === 40) {
      if (document.querySelector('#services-dropdown-container').classList.contains('open')) {
        const desktopMenu = document.querySelector('.services-dropdown-menu'),
          serviceDropdown = document.querySelector('#services-dropdown-btn');
        const allLinks = desktopMenu.getElementsByTagName('a');

        event.preventDefault();

        if (event.keyCode === 38) {
          for (var i = 0; i < allLinks.length; i++) {
            if (allLinks[i] === event.target) {
              if (allLinks[i - 1] !== undefined) {
                allLinks[i - 1].focus();

                return;
              } else {
                serviceDropdown.focus();
              }
            }
          }
        }

        if (event.keyCode === 40) {
          if (event.target.id == 'services-dropdown-btn') {
            allLinks[0].focus();
          }

          for (var i = 0; i < allLinks.length; i++) {
            if (allLinks[i] === event.target) {
              if (allLinks[i + 1] !== undefined) {
                allLinks[i + 1].focus();

                return;
              } else {
                serviceDropdown.focus();
              }
            }
          }
        }
      }

      const aboutUsDropdownMenu = document.querySelector('.about-us-dropdown-menu');

      if (aboutUsDropdownMenu.classList.contains('open')) {
        const serviceDropdown = document.querySelector('#about-us-dropdown-btn');
        const allLinks = aboutUsDropdownMenu.getElementsByTagName('a');

        event.preventDefault();

        if (event.keyCode === 38) {
          for (var i = 0; i < allLinks.length; i++) {
            if (allLinks[i] === event.target) {
              if (allLinks[i - 1] !== undefined) {
                allLinks[i - 1].focus();

                return;
              } else {
                serviceDropdown.focus();
              }
            }
          }
        }

        if (event.keyCode === 40) {
          if (event.target.id == 'about-us-dropdown-btn') {
            allLinks[0].focus();
          }

          for (var i = 0; i < allLinks.length; i++) {
            if (allLinks[i] === event.target) {
              if (allLinks[i + 1] !== undefined) {
                allLinks[i + 1].focus();

                return;
              } else {
                serviceDropdown.focus();
              }
            }
          }
        }
      }
    }
  });

  // Using this script to use focus-visible on Safari
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://unpkg.com/focus-visible@5.2.0/dist/focus-visible.min.js';
  script.integrity = 'sha384-xRa5B8rCDfdg0npZcxAh+RXswrbFk3g6dlHVeABeluN8EIwdyljz/LqJgc2R3KNA';
  script.crossOrigin = 'anonymous';
  document.body.appendChild(script);
  // End of focus-visible script

  document.querySelectorAll('.slvStatesNoED').forEach(function (el) {
    el.innerHTML = slvStatesNoED;
  });
  document.querySelectorAll('.slvStatesEDOnly').forEach(function (el) {
    el.innerHTML = slvStatesEDOnly;
  });
  document.querySelectorAll('.slvStatesNoEDText').forEach(function (el) {
    el.innerHTML = slvStatesNoEDText;
  });
  document.querySelectorAll('.slvStatesEDOnlyText').forEach(function (el) {
    el.innerHTML = slvStatesEDOnlyText;
  });
  document.querySelectorAll('.serviceAvailableStatesGeneral').forEach(function (el) {
    el.innerHTML = serviceAvailableStatesGeneral;
  });
  document.querySelectorAll('.serviceAvailableStatesTreatment').forEach(function (el) {
    el.innerHTML = serviceAvailableStatesTreatment;
  });
  document.querySelectorAll('.serviceAvailableStatesLabs').forEach(function (el) {
    el.innerHTML = serviceAvailableStatesLabs;
  });
  document.querySelectorAll('.sprintecStates').forEach(function (el) {
    el.innerHTML = sprintecStates;
  });
  document.querySelectorAll('.orthoStates').forEach(function (el) {
    el.innerHTML = orthoStates;
  });
  document.querySelectorAll('.errinStates').forEach(function (el) {
    el.innerHTML = errinStates;
  });
  document.querySelectorAll('.edStates').forEach(function (el) {
    el.innerHTML = edStates;
  });
  document.querySelectorAll('.bloodTestStates').forEach(function (el) {
    el.innerHTML = bloodTestStates;
  });
  document.querySelectorAll('.stdLabsStates').forEach(function (el) {
    el.innerHTML = stdLabsStates;
  });
  document.querySelectorAll('.serviceFee').forEach(function (el) {
    el.innerHTML = serviceFee;
  });
  document.querySelectorAll('.edocpserviceFee').forEach(function (el) {
    el.innerHTML = edocpserviceFee;
  });
  document.querySelectorAll('.sinusInfectionFee').forEach(function (el) {
    el.innerHTML = sinusInfectionFee;
  });
  document.querySelectorAll('.cholesterolLabFee').forEach(function (el) {
    el.innerHTML = cholesterolLabFee;
  });
  document.querySelectorAll('.cholesterolVisitAndLabFee').forEach(function (el) {
    el.innerHTML = cholesterolVisitAndLabFee;
  });
  document.querySelectorAll('.a1cLabFee').forEach(function (el) {
    el.innerHTML = a1cLabFee;
  });
  document.querySelectorAll('.a1cVisitAndLabFee').forEach(function (el) {
    el.innerHTML = a1cVisitAndLabFee;
  });
  document.querySelectorAll('.aboLabFee').forEach(function (el) {
    el.innerHTML = aboLabFee;
  });
  document.querySelectorAll('.aboVisitAndLabFee').forEach(function (el) {
    el.innerHTML = aboVisitAndLabFee;
  });
  document.querySelectorAll('.utiVisitFee').forEach(function (el) {
    el.innerHTML = utiVisitFee;
  });
  document.querySelectorAll('.stdVisitFee').forEach(function (el) {
    el.innerHTML = stdVisitFee;
  });
  document.querySelectorAll('.stdLabFee').forEach(function (el) {
    el.innerHTML = stdLabFee;
  });
  document.querySelectorAll('.stdVisitAndLabFee').forEach(function (el) {
    el.innerHTML = stdVisitAndLabFee;
  });
  document.querySelectorAll('.stdVisitAndLabFeeUnder').forEach(function (el) {
    el.innerHTML = stdVisitAndLabFeeUnder;
  });
});

function switchTabindexes(elements) {
  setTimeout(function () {
    elements.forEach(function (element) {
      if (+element.getAttribute('tabindex') < 0) {
        return element.setAttribute('tabindex', '1+');
      }
      return element.setAttribute('tabindex', '-1');
    });
  }, 0);
}

document.addEventListener('keydown', function (event) {
  if (document.activeElement && event.keyCode === 13) {
    event.preventDefault();

    document.activeElement.click();
  }

  if (document.activeElement && event.keyCode === 9) {
    if (
      document.activeElement.firstElementChild ===
      document.querySelector('.mobile-apps-banner__buttons-holder__android')
    ) {
      document.querySelector('#services-dropdown-btn').focus();
      event.preventDefault();
    }
  }

  if (document.activeElement && event.keyCode === 27) {
    const serviceDropdown = document.querySelector('#services-dropdown-btn');
    if (serviceDropdown && serviceDropdown.getAttribute('aria-expanded') === 'true') {
      const dropdownLinks = document.querySelectorAll('.services-dropdown-menu a');
      serviceDropdown.click();
    }
    if (document.querySelector('.about-us-dropdown-menu.open')) {
      document.querySelector('#about-us-dropdown-btn').click();
    }
  }
});

function optimizely_ga_tracking() {

  window.dataLayer = window.dataLayer || [];

  let optimizely_data = getCookie('optimizely_data');

  if (optimizely_data) {
    window.dataLayer.push({
      event: 'optimizely_tracking',
      eventCategory: 'optimizely_tracking',
      eventAction: 'landing_brochure_page',
      eventLabel: optimizely_data,
    });
  }
}

//***************** CACHE EXPERIMENT ID'S AND VARIANT ID'S *****************//

window.addEventListener('load', function () {

  console.log("load COMPLETE (global.js)");

  if (window.okayToTrackUser && typeof window.okayToTrackUser === 'function' && !window.okayToTrackUser()) {
    return;
  }

  function getActiveExperiments() {

    if (!window.optimizelyEdge && !window.optimizely) {
      console.log('No optimizelyEdge nor optimizelyWeb loaded in the browser (global.js)');
      return null;
    }

    if (window.optimizelyEdge && typeof window.optimizelyEdge.get === 'function') {
      try {
        return window.optimizelyEdge.get('state').getActiveExperiments();
      } catch (err) {
        console.error('Error fetching active experiments from optimizelyEdge:', err);
      }
    } else if (window.optimizely && typeof window.optimizely.get === 'function') {
      try {
        return window.optimizely.get('state').getExperimentStates();
      } catch (err) {
        console.error('Error fetching active experiments from optimizelyWeb:', err);
      }
    }

    return null;
  }

  function transformActiveExperiments(activeExperiments) {

    let transformedData = {};

    if (activeExperiments) {
      for (const key in activeExperiments) {
        const experiment = activeExperiments[key];
        if (experiment.id && experiment.variation && experiment.variation.id) {
          transformedData[key] = {
            exp_id: experiment.id,
            exp_name: experiment.name,
            var_id: experiment.variation.id,
            var_name: experiment.variation.name
          };
        }
      }
    }

    return transformedData;
  }

  function cacheVariantExtendedID() {

    let transformedString;
    const hostname = window.location.hostname;
    const activeExperiments = getActiveExperiments();
    const transformedData = transformActiveExperiments(activeExperiments);

    if (Object.keys(transformedData).length > 0) {

      transformedString = JSON.stringify(transformedData);
      setCookie('optimizely_full_data', transformedString, 7, 'days');

      let optimizely_data = "";
      for (const key in transformedData) {
        const experiment = transformedData[key];
        if (experiment.exp_id && experiment.var_id) {
          const exp_data = "[" + experiment.exp_id + "|" + experiment.var_id + "]";
          if (optimizely_data) {
            optimizely_data = optimizely_data + "," + exp_data;
          } else {
            optimizely_data = exp_data;
          }
        }
      }
      setCookie('optimizely_data', optimizely_data, 7, 'days');
    }
  }

  cacheVariantExtendedID();

});

document.addEventListener('DOMContentLoaded', function () {

  console.log("DOMContentLoaded COMPLETE (global.js)");

});
