import { Splide } from '@splidejs/splide';
import { removeDisplay, getAssetUrl } from './utils';
import { ServiceCategory, services } from './services';

let heroSlider = null;
let newHeroCarouselShouldBeMounted = true;
let isFirstInitialization = true;
const isMobile = () => window.innerWidth <= 768;
const heroSliderElement = document.querySelector('#product-slider');

if(heroSliderElement) {
  heroSlider = new Splide('#product-slider', {
    arrows: false,
    autoWidth: true,
    trimSpace: 'move',
    pagination: false,
    drag: false,
    breakpoints: {
      1023: {
        trimSpace: true,
      },
    },
  });
}

function fadeInAdditionalImage() {
  if (!isMobile()) return;

  const additionalImageElements = document.querySelectorAll('.cards-item__img-meditation');

  additionalImageElements.forEach((img, index) => {
    setTimeout(() => {
      img.style.opacity = '1';
    }, isFirstInitialization ? 3000 : 0);
  });

  isFirstInitialization = false;
}

function scrollToServices() {
  const scrollDiv = document.getElementById('new-services');
  scrollDiv.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  });
}

function selectNavItem(id) {
  document.querySelectorAll('.nav-item').forEach(item => item.classList.remove('selected'));
  document.querySelector(id).classList.add('selected');
}

function getProductImgDetails(product) {
  if (product === null) return null;

  return {
    class: product.class,
    src: product.src,
  };
}

function filterServices(category) {
  const desktopContainer = document.querySelector('.homepage-carousel .splide__list');

  let filteredServices = services[category];

  if (isMobile() && filteredServices.length > 4) {
    filteredServices = filteredServices.slice(0, 4);
  }
 const mobileContainer = document.querySelector('.homepage-carousel .new-hero__popular-section__cards.mobile-only');

  configureCarousel();

  // Clear existing content
  desktopContainer.innerHTML = '';
  mobileContainer.innerHTML = '';

  let mobileServicesToView = '';
  let desktopServicesToView = '';

  filteredServices.forEach(service => {
    const productImgDetails = getProductImgDetails(service.product);
    const mobileImageUrl = getAssetUrl(`images/${service.images.mobile}`);
    const desktopImageUrl = getAssetUrl(`images/${service.images.desktop}`);
    const placeholderImage = service.images.placeholder || '';
    const additionalImage = productImgDetails ? `
    <img class="cards-item__img-meditation ${productImgDetails.class}"
      alt="product-image" src="${productImgDetails.src}"
      style="opacity: ${isMobile() ? '0' : '1'}; ${isMobile() ? 'transition: opacity 0.5s ease-in-out;' : ''}"
    >
  ` : '';

    const serviceElement = `
      <a href="${service.linkUrl}">
        <div class="cards-item">
          <img
            class="cards-item__img"
            src="${placeholderImage}"
            data-src="${mobileImageUrl}"
            data-srcset="${mobileImageUrl} 480w, ${desktopImageUrl} 768w"
            sizes="(max-width: 767px) 480px, 768px"
            alt="service-image"
            onload="this.onload=null; this.srcset=this.getAttribute('data-srcset'); this.src=this.getAttribute('data-src'); this.removeAttribute('data-srcset'); this.removeAttribute('data-src');">
          ${additionalImage}
          <div class="cards-item__content">
            <p class="cards-item__content__title ${service.customTitleStyle || ''}">${service.title}</p>
          </div>
          <div class="cards-item__right-arrow"></div>
        </div>
      </a>
    `;

    mobileServicesToView += serviceElement;
    desktopServicesToView += `<li class="splide__slide">${serviceElement}</li>`;
  });

  // Append new content
  mobileContainer.innerHTML = mobileServicesToView;
  desktopContainer.innerHTML = desktopServicesToView;

  // Fade in additional images with different delays based on initialization
  fadeInAdditionalImage();

  // Refresh carousel
  heroSlider.refresh();
  heroSlider.go(0);

}

function configureCarousel() {
  const carousel = document.querySelector('.carousel');

  if (carousel && newHeroCarouselShouldBeMounted) {
    const chevronLeft = document.querySelector('.icon-ic-arrow-left.navigation__control-prev');
    const chevronRight = document.querySelector('.icon-ic-arrow-right.navigation__control-next');

    heroSlider.on('mounted', () => newHeroCarouselShouldBeMounted = false);

    const updateChevronState = (slide) => {
      chevronLeft.classList.toggle('inactive', slide.index === 0);
      chevronRight.classList.toggle('inactive', slide.index === heroSlider.length - 1);
    };

    heroSlider.on('visible', updateChevronState);
    heroSlider.on('hidden', updateChevronState);

    chevronLeft.onclick = () => heroSlider.go('<');
    chevronRight.onclick = () => heroSlider.go('>');

    heroSlider.mount();
  }
}

function setupHeroHomepage() {
  if (!heroSlider) return;
  const categories = [
    { id: '#popular', label: ServiceCategory.Popular },
    { id: '#mental-health', label: ServiceCategory.MentalHealth },
    { id: '#mens-health', label: ServiceCategory.MensHealth },
    { id: '#skin', label: ServiceCategory.Skin },
    { id: '#womens-health', label: ServiceCategory.WomensHealth },
    { id: '#general-health', label: ServiceCategory.GeneralHealth },
    { id: '#testing', label: ServiceCategory.Testing }
  ];

  categories.forEach(({ id, label }) => {
    document.querySelector(id).addEventListener('click', () => {
      selectNavItem(id);
      filterServices(label);
    });
  });

  document.querySelector('#see-all').addEventListener('click', scrollToServices);

  filterServices(ServiceCategory.Popular);
  selectNavItem('#popular');
}

export {
  setupHeroHomepage
}
