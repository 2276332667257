import { ASSETS_URL } from './constants';

export function equalHeight(selector) {
  selector = selector || '.team-member';

  var members = document.querySelectorAll(selector),
    tallest = 0;

  for (var i = 0; i < members.length; i++) {
    members[i].style.height = 'auto';
    if (members[i].clientHeight > tallest) {
      tallest = members[i].clientHeight;
    }
  }
  var testimonialControls = document.querySelector('.testimonial-controls');

  if (selector === '.jcarousel li' && testimonialControls) {
    if (window.innerWidth > 767) {
      testimonialControls.style.height = tallest + 'px';
    } else {
      testimonialControls.style.height = 'inherit';
    }
  }
}

export function capitalizer(text) {
  return text
    .toString()
    .replace(/[_]/g, ' ')
    .replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
}


export function debounce(callback, time) {
  var interval;
  return function () {
    clearTimeout(interval);
    interval = setTimeout(function () {
      interval = null;

      // eslint-disable-next-line
      callback(arguments);
    }, time);
  };
}

export function getCookie() {
  var cookieObj = {},
    cookies = document.cookie;
  cookies = cookies.split('; ');
  cookies.forEach(function (v) {
    var splitCookie = v.split('=');
    cookieObj[splitCookie[0]] = splitCookie[1];
  });
  return cookieObj;
}

export const removeDisplay = (menuSelector) => {
  const menuElement = document.querySelector(menuSelector);
  if (menuElement) {
    menuElement.style.display = '';
  }
};

export const getAssetUrl = (path) => {
  if (path.startsWith('/')) {
      path = path.substring(1);
  }
  let url = `${ASSETS_URL}/${path}`;
  url = url.replace(/([^:]\/)\/+/g, '$1');
  return url;
};
