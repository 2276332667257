import _ from 'lodash';

//******** ENDPOINTS *********//

var tracking_base = tracking_url + 'visits';
var tracking_uuid = tracking_url + 'uuid';
var trackMongoPayload = {};

window.automaticMongoClickTracking = function automaticMongoClickTracking(event) {

  function processLink(innerText, href, elem) {
    var target = elem.getAttribute('target');

    if (href) event.preventDefault();

    trackAction("click", [ window.location.pathname, innerText ? innerText : "empty", "link", href ? href : "not found" ], href ? { href: href, target: target ? target : "" } : null);
  }

  function processButton(innerText, elem) {
    var onClick = elem.getAttribute('onclick');

    trackAction("click", [ window.location.pathname, innerText ? innerText : "empty", "button", onClick ? onClick : "not found" ], null);
  }

  var elem = null;
  var parent = null;
  var grandParent = null;

  if (event && event.target) {
    elem = event.target;
    if (event.target.parentElement) {
      parent = event.target.parentElement;
      if (event.target.parentElement.parentElement) {
         grandParent = event.target.parentElement.parentElement;
      }
    }
  }

  if (elem && elem.tagName.toLowerCase() === 'a') {

    processLink(elem.innerText, elem.href, elem);

  } else if (elem && elem.tagName.toLowerCase() === 'button') {

    processButton(elem.innerText, elem);

  } else if (parent && parent.tagName.toLowerCase() === 'a') {

    processLink(parent.innerText, parent.href, parent);

  } else if (parent && parent.tagName.toLowerCase() === 'button') {

    processButton(parent.innerText, parent);

  } else if (grandParent && grandParent.tagName.toLowerCase() === 'a') {

    processLink(grandParent.innerText, grandParent.href, grandParent);

  } else if (grandParent && grandParent.tagName.toLowerCase() === 'button') {

    processButton(grandParent.innerText, grandParent);
  }
}

window.trackAction = function trackAction(action, attribute, urlToGo) {

  console.log("mon-tra.js tra-act before OTT");

  if (window.okayToTrackUser && typeof window.okayToTrackUser === 'function' && !window.okayToTrackUser()) {
    processUrlToGo(urlToGo);
    return;
  }

  console.log("mon-tra.js tra-act after OTT");

  var payload = {};
  var actionDetails = [];

  payload.actionLog = {};
  payload.actionLog.event = action;

  if (attribute && Array.isArray(attribute) && attribute.length) {

    attribute.forEach( function (data, index) {

      if (index === 0 && data) {
        payload.actionLog.screen = data;
      }
      if (index === 1 && data) {
        payload.actionLog.label = data;
      }
      if (index === 2 && data) {
        payload.actionLog.type = data;
      }
      if (index === 3 && data) {
        payload.actionLog.target = data;
      }
      if (index > 3 && data) {
        actionDetails.push(data);
      }
    });

    payload.actionLog.details = JSON.stringify(actionDetails);

  } else if (attribute && attribute.dataArray && Array.isArray(attribute.dataArray) && attribute.dataArray.length) {

    attribute.dataArray.forEach( function (data, index) {

      if (index === 0 && data) {
        payload.actionLog.screen = data;
      }
      if (index === 1 && data) {
        payload.actionLog.label = data;
      }
      if (index === 2 && data) {
        payload.actionLog.type = data;
      }
      if (index === 3 && data) {
        payload.actionLog.target = data;
      }
      if (index > 3 && data) {
        actionDetails.push(data);
      }
    });

    payload.actionLog.details = JSON.stringify(actionDetails);

  } else if (attribute && attribute.actionLog) {

    payload.actionLog = attribute.actionLog;
    payload.actionLog.event = action;

  }

  trackMongoPayload = Object.assign(trackMongoPayload, payload);
  trackMongo(urlToGo);
}

var trackMongo = function(urlToGo, trackABTesting = false) {

  // PLEASE DO NOT DELETE
  // DATE CONVERSIONS
  /*
  var dateLocal = (new Date()).toISOString().substring(0, 10);;
  var datePacific = (new Date(new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"}))).toISOString().substring(0, 10);
  var dateNewYork = (new Date(new Date().toLocaleString("en-US", {timeZone: "America/New_York"}))).toISOString().substring(0, 10);
  var dateSydney = (new Date(new Date().toLocaleString("en-US", {timeZone: "Australia/Sydney"}))).toISOString().substring(0, 10);

  console.log('dateLocal:', dateLocal);
  console.log('datePacific:', datePacific);
  console.log('dateNewYork:', dateNewYork);
  console.log('dateSydney:', dateSydney);
  */

  trackMongoPayload.environment = environment;

  trackMongoPayload.date_pacific = (new Date(new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"}))).toISOString().substring(0, 10);


  // Brochure / Clinic - Field 'searchParsed' for url query parameters has to be a string
  // https://23andme.atlassian.net/browse/LMN-1601

  var search = window.location.search;
  if (!search) search = "";

  trackMongoPayload.url_parameters = search;
  trackMongoPayload.url_parameters_string = search;


  // Example: '/drug/viagra'
  trackMongoPayload.screen_id = window.location.pathname;

  var payload = {};

  try {

    // No data to put here?
    payload.OS_type = '';

    // Will give us the user agent string in case OS_type and OS_model are not enough/reliable
    payload.OS_version = window.navigator.userAgent;

    // Not long term reliable going forward since userAgent and other stuff will change
    payload.OS_model = getBrowserName();

    // Should tell us mac/windows/ios/android
    payload.manufacturer = window.navigator.oscpu || window.navigator.platform;

    payload.availableMemory = '';

  } catch(err) {

  }

  // Tracking details of User Agent
  payload.userAgent = {};

  try {
    var user = detectService().parse(window.navigator.userAgent);

    if (user) {
      if(user.device) {
        payload.userAgent.device = user.device;
      }
      if(user.os) {
        payload.userAgent.os = user.os;
      }
      if(user.browser) {
        payload.userAgent.browser = user.browser;
      }
    }
  } catch(err) {

  }

  // Tracking requests coming from web
  payload.platform = 'web';

  // SPRINT 139
  // We set 'clinic' in Clinic and 'brochure' in Brochure
  payload.application = 'brochure';

  payload.brochureArrivalUrl = {};
  payload.brochureArrivalUrl.ancestorOrigins = {};
  payload.brochureArrivalUrl.hash = window.location.hash;
  payload.brochureArrivalUrl.host = window.location.host;
  payload.brochureArrivalUrl.hostname = window.location.hostname;
  payload.brochureArrivalUrl.href = window.location.href;
  payload.brochureArrivalUrl.origin = window.location.origin;
  payload.brochureArrivalUrl.pathname = window.location.pathname;
  payload.brochureArrivalUrl.port = window.location.port;
  payload.brochureArrivalUrl.protocol = window.location.protocol;
  payload.brochureArrivalUrl.search = window.location.search;
  payload.brochureOriginatingUrl = document.referrer;

  // Brochure / Clinic - Field 'searchParsed' for url query parameters has to be a string
  // https://23andme.atlassian.net/browse/LMN-1601
  payload.brochureArrivalUrl.searchParsed = payload.brochureArrivalUrl.search;

  // Attach google session
  payload.google_client_id = getGoogleClientId();

  // Track Optimizely Data on S3
  let optimizely_data = getCookie('optimizely_data');
  optimizely_data = (optimizely_data ? optimizely_data : '');
  payload.optimizely_data = optimizely_data;

  trackMongoPayload = Object.assign(trackMongoPayload, payload);

  ajaxGetUserUuid(getUserUuid(), urlToGo, trackABTesting);
}

window.trackScreen = function trackScreen() {

  console.log("mon-tra.js tra-scr before OTT");

  if (window.okayToTrackUser && typeof window.okayToTrackUser === 'function' && !window.okayToTrackUser()) {
    processUrlToGo(urlToGo);
    return;
  }

  console.log("mon-tra.js tra-scr after OTT");

  let urlToGo = "";
  let trackABTesting = true;

  trackMongo(urlToGo, trackABTesting);
}

export function getCookie(cname) {
  var cookieObj = {}, cookies = document.cookie;
  cookies = cookies.split('; ');
  _.forEach(cookies, function(v){
    var splitCookie = v.split('=');
    cookieObj[splitCookie[0]] = splitCookie[1];
  });

  if (!cookieObj[cname] || cookieObj[cname].length < 1) return "";
  return cookieObj[cname];
}

export function setCookie(cname, cvalue, exp_units, type) {
  var d = new Date();
  var exp_milliseconds;

  if (type == 'years') exp_milliseconds = exp_units * 365 * 24 * 60 * 60 * 1000;
  if (type == 'days') exp_milliseconds = exp_units * 24 * 60 * 60 * 1000;
  if (type == 'hours') exp_milliseconds = exp_units * 60 * 60 * 1000;
  if (type == 'minutes') exp_milliseconds = exp_units * 60 * 1000;

  d.setTime(d.getTime() + exp_milliseconds);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + window.cookies_domain + ";path=/;Secure";
}

var getUrlParameters = function (parameters) {

  var found = false;
  var searchParsed = {};

  if (parameters) {
    var search = parameters.substring(1);
    var params = search.split("&");

    params.forEach(function (param) {
        var pair = param.split("=");
        searchParsed[pair[0]] = pair[1];
        found = true;
    });
  }

  if (found) return searchParsed;
  return null;
}

function getGoogleClientId() {
  var cookieObj = {}, cookies = document.cookie;
  cookies = cookies.split('; ');
  _.forEach(cookies, function(v){
    var splitCookie = v.split('=');
    cookieObj[splitCookie[0]] = splitCookie[1];
  });

  if (!cookieObj._ga || cookieObj._ga.length < 1) return "";
  return cookieObj._ga;
}

export function getUserUuid() {
  var cookieObj = {}, cookies = document.cookie;
  cookies = cookies.split('; ');
  _.forEach(cookies, function(v){
    var splitCookie = v.split('=');
    cookieObj[splitCookie[0]] = splitCookie[1];
  });

  if (!cookieObj.user_uuid || cookieObj.user_uuid.length < 1) return "";
  return cookieObj.user_uuid;
}

function getSessionUuid() {
  var cookieObj = {}, cookies = document.cookie;
  cookies = cookies.split('; ');
  _.forEach(cookies, function(v){
    var splitCookie = v.split('=');
    cookieObj[splitCookie[0]] = splitCookie[1];
  });

  if (!cookieObj.session_uuid || cookieObj.session_uuid.length < 1) return "";
  return cookieObj.session_uuid;
}

window.checkService = function checkService(service_name) {
  var userID = getUserUuid();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'get-started',
    get_started: userID,
    get_started_service: service_name,
  });
}

function processUrlToGo(urlToGo) {
  if (urlToGo) {
    if (urlToGo.target) {
      var redirectWindow = window.open(urlToGo.href, urlToGo.target);
      redirectWindow.location;
    } else {
      window.location.href = urlToGo.href;
    }
  }
}

function clinicABTesting(userUuid, pathname) {

  if (userUuid) {

    var request = new XMLHttpRequest();

    if (!request) {

      console.log('Error calling new XMLHttpRequest()');

    } else {

      request.onreadystatechange = processAjaxResponse;
      request.open('GET', apiUrl + 'content/web/ClinicABTestingExperimentsList', true);
      request.setRequestHeader("Content-Type", 'application/json');
      request.setRequestHeader("Accept", 'application/json');

      request.onload = function(data) {

        if (this.status >= 200 && this.status < 400) {

          let experimentsList = JSON.parse(data.target.response);
          let today = new Date().toISOString().slice(0, 10);
          let experimentFound = null;
          let found = false;

          if (experimentsList && experimentsList.length) {
            experimentsList.forEach((experiment) => {
              if (!found && experiment.enabled && experiment.clinic_page_code == 'BrochureAllPages' && experiment.service_code == 'ALL' &&
              experiment.start_date_iso_format <= today && experiment.end_date_iso_format >= today) {
                found = true;
                experimentFound = experiment;
              }
            });

            if (experimentFound) processExperiment(userUuid, experimentFound.experiment_id, pathname);
          }
        } else {
          console.log("clinicABTesting error: " + data.target.response + " status " + data.target.responseText);
        }
      };

      function processAjaxResponse() {
        if (request.readyState === XMLHttpRequest.DONE) {

          if (request.status >= 200 && request.status < 500) {

          } else {
            console.log('Error calling GET ' + 'content/web/ClinicABTestingExperimentsList');
          }
        }
      }
    }

    request.send();
  }
}

function processExperiment(userUuid, experiment_CMS_placeholder, pathname) {

  if (userUuid && experiment_CMS_placeholder) {

    var request = new XMLHttpRequest();

    if (!request) {

      console.log('Error calling new XMLHttpRequest()');

    } else {

      request.onreadystatechange = processAjaxResponse;
      request.open('GET', apiUrl + 'content/web/' + experiment_CMS_placeholder, true);
      request.setRequestHeader("Content-Type", 'application/json');
      request.setRequestHeader("Accept", 'application/json');

      request.onload = function(data) {

        if (this.status >= 200 && this.status < 400) {

          let experimentDetail = JSON.parse(data.target.response);

          if (experimentDetail && experimentDetail.experiment_id == experiment_CMS_placeholder) {

            if (experimentDetail.unique_identifier == 'User-UUID' && experimentDetail.number_digits && experimentDetail.experiment_mapping) {

              let digits_used = userUuid.substr(6, experimentDetail.number_digits);
              let variant = experimentDetail.experiment_mapping[digits_used];
              experimentDetail.digits_used = digits_used;
              experimentDetail.user_uuid = userUuid;

              if (variant) {

                let new_pathnames = null;
                experimentDetail.variant = variant;
                if (experimentDetail.replicated_url_variants && experimentDetail.replicated_url_variants[variant]) new_pathnames = experimentDetail.replicated_url_variants[variant];
                if (experimentDetail.experiment_mapping) delete experimentDetail.experiment_mapping;
                if (experimentDetail.replicated_url_variants) delete experimentDetail.replicated_url_variants;
                trackMongoPayload.variableContentLog = experimentDetail;

                if (new_pathnames) {
                  let links = document.querySelectorAll('a');
                  links.forEach(function (link) {
                    let href = link.getAttribute('href');
                    if (new_pathnames[href]) link.setAttribute('href', new_pathnames[href]);
                  });
                }

                // POST TRACKING
                ajaxPostTracking("", false);
              }
            }
          }
        } else {
          console.log("processExperiment error: " + data.target.response + " status " + data.target.responseText);
        }
      };

      function processAjaxResponse() {
        if (request.readyState === XMLHttpRequest.DONE) {

          if (request.status >= 200 && request.status < 500) {

          } else {
            console.log('Error calling GET ' + 'content/web/' + experiment_CMS_placeholder);
          }
        }
      }
    }

    request.send();
  }
}

function ajaxGetUserUuid(userUuid, urlToGo, trackABTesting = false) {

  if (userUuid) {

    trackMongoPayload.user_uuid = userUuid;
    ajaxGetSessionUuid(getSessionUuid(), urlToGo, trackABTesting);

  } else {

    var request = new XMLHttpRequest();

    if (!request) {

      console.log('Error calling new XMLHttpRequest()');
      processUrlToGo(urlToGo);

    } else {

      request.onreadystatechange = processAjaxResponse;
      // request.open('GET', 'https://dev-sapi.polkadoc.com/tracking/v1/' + 'uuid', true);
      request.open('GET', tracking_uuid, true);
      request.setRequestHeader("Content-Type", 'application/json');
      request.setRequestHeader("Accept", 'application/json');
      request.setRequestHeader("X-Api-Key", tracking_api_key);

      request.onload = function(data) {

        if (this.status >= 200 && this.status < 400) {
          var user_uuid = JSON.parse(data.target.response).value;
          setCookie('user_uuid', user_uuid, 2, 'years');
          trackMongoPayload.user_uuid = user_uuid;
          ajaxGetSessionUuid(getSessionUuid(), urlToGo, trackABTesting);

        } else {
          processUrlToGo(urlToGo);
          console.log("Tracking API error: " + data.target.response + " status " + data.target.responseText);
        }
      };

      function processAjaxResponse() {
        if (request.readyState === XMLHttpRequest.DONE) {

          if (request.status >= 200 && request.status < 500) {

          } else {
            processUrlToGo(urlToGo);
            console.log('Error calling GET ' + tracking_uuid);
          }
        }
      }
    }

    request.send();
  }
}

function ajaxGetSessionUuid(sessionUuid, urlToGo, trackABTesting = false) {

  if (sessionUuid) {

    trackMongoPayload.session_uuid = sessionUuid;
    ajaxPostTracking(urlToGo, trackABTesting);

  } else {

    var request = new XMLHttpRequest();

    if (!request) {

      console.log('Error calling new XMLHttpRequest()');
      processUrlToGo(urlToGo);

    } else {

      request.onreadystatechange = processAjaxResponse;
      // request.open('GET', 'https://dev-sapi.polkadoc.com/tracking/v1/' + 'uuid', true);
      request.open('GET', tracking_uuid, true);
      request.setRequestHeader("Content-Type", 'application/json');
      request.setRequestHeader("Accept", 'application/json');
      request.setRequestHeader("X-Api-Key", tracking_api_key);

      request.onload = function(data) {

        if (this.status >= 200 && this.status < 400) {
          var session_uuid = JSON.parse(data.target.response).value;
          setCookie('session_uuid', session_uuid, 30, 'minutes');
          trackMongoPayload.session_uuid = session_uuid;
          ajaxPostTracking(urlToGo, trackABTesting);

        } else {
          processUrlToGo(urlToGo);
          console.log("Tracking API error: " + data.target.response + " status " + data.target.responseText);
        }
      };

      function processAjaxResponse() {
        if (request.readyState === XMLHttpRequest.DONE) {

          if (request.status >= 200 && request.status < 500) {

          } else {
            processUrlToGo(urlToGo);
            console.log('Error calling GET ' + tracking_uuid);
          }
        }
      }

      request.send();
    }
  }
}

function ajaxPostTracking(urlToGo, trackABTesting = false) {

  var payload = JSON.stringify(trackMongoPayload);
  var request = new XMLHttpRequest();

  if (!request) {

    console.log('Error calling new XMLHttpRequest()');
    processUrlToGo(urlToGo);

  } else {

    request.onreadystatechange = processAjaxResponse;
    // request.open('POST', 'https://dev-sapi.polkadoc.com/tracking/v1/' + 'visits', true);
    request.open('POST', tracking_base, true);
    request.setRequestHeader("Content-Type", 'application/json');
    request.setRequestHeader("Accept", 'application/json');
    request.setRequestHeader("Authorization", '');
    request.setRequestHeader('X-Service-Code', xServiceCode);
    request.setRequestHeader('X-Client-Name', xClientName);
    request.setRequestHeader('X-Client-Version', 'v2.0.0');
    request.setRequestHeader('X-Api-Key', tracking_api_key);

    request.onload = function(data) {

      if (this.status >= 200 && this.status < 400) {
        let pathname = window.location.pathname;
        processUrlToGo(urlToGo);
        if (trackABTesting) clinicABTesting(trackMongoPayload.user_uuid, pathname);

      } else {
        processUrlToGo(urlToGo);
        console.log("Tracking API error: " + data.target.response + " status " + data.target.responseText);
      }
    };

    function processAjaxResponse() {
      if (request.readyState === XMLHttpRequest.DONE) {

        if (request.status >= 200 && request.status < 500) {

        } else {
          processUrlToGo(urlToGo);
          console.log('Error calling POST ' + tracking_base);
        }
      }
    }

    request.send(payload);
  }
}

function getBrowserName() {
  var currentBrowser = '';
  const browsers = {};

  // Opera 8.0+
  browsers.opera = (!!window.opr) || !!window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0;
  // Firefox 1.0+
  browsers.firefox = typeof InstallTrigger !== 'undefined';
  // Safari <= 9 '[object HTMLElementConstructor]'
  browsers.safari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
  // Internet Explorer 6-11
  browsers.ie = /* @cc_on!@*/false || !!document.documentMode;
  // Edge 20+
  browsers.edge = !browsers.ie && !!window.StyleMedia;
  // Chrome 1+
  browsers.chrome = !!window.chrome && !!window.chrome.webstore;
  // Blink engine detection
  browsers.blink = (browsers.chrome || browsers.opera) && !!window.CSS;
  // updated for safari 10.  test after chrome and opera
  browsers.safari10 = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 ||
    (!browsers.chrome && !browsers.opera && window.webkitAudioContext !== undefined);

  Object.keys(browsers).forEach(function (k) {
    if (Object.prototype.hasOwnProperty.call(browsers, k)) {
      if (browsers[k]) {
        if (k === 'blink') {
          currentBrowser = 'chrome blink';
          if (browsers.opera) {
            currentBrowser = 'opera blink';
          }
        } else {
          currentBrowser = k;
        }
      }
    }
  });

  return currentBrowser;
}

// PLEASE DO NOT DELETE
// EXAMPLE OF ERROR TRACKING IN MONGO

/*
var trackError = function (error, functionName) {

  var payload = {};
  var errorLog = error;

  if (errorLog && errorLog.errApi && errorLog.errApi.config && errorLog.errApi.config.data) delete errorLog.errApi.config.data;
  if (errorLog && errorLog.config && errorLog.config.data) delete errorLog.config.data;

  payload.errorLog = JSON.stringify(errorLog);
  payload.funcName = functionName;

  trackMongoPayload = Object.assign(trackMongoPayload, payload);
  trackMongo();
}

$.ajax({
  url: 'https://<API subdomain>/v2.0/users/me/eligibility?zip=999999999',
  headers:
    {
      'Content-Type':'application/json',
      'Accept':'application/json'
    },
  method: 'GET',
  success: function(data, textStatus, jqXHR) {

    console.log("API response: ", data);
    console.log("API jqXHR: ", jqXHR);
    console.log("API result: " + textStatus + " status: " + jqXHR.status);
  },
  error: function(jqXHR, textStatus, errorThrown) {

    console.log("API jqXHR: ", jqXHR);
    console.log("API errorThrown: ", errorThrown);
    console.log("API error: " + textStatus + " status: " + jqXHR.status + " " + errorThrown);

    if (jqXHR && jqXHR.responseJSON) trackError({ data: jqXHR.responseJSON }, 'GET users/me/eligibility?zip');
  }
});
*/

document.addEventListener('click', automaticMongoClickTracking);
